/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import add from '../image/icon-card/add.png'

import interested from '../image/icon-card/interested.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Left from '../Accueil/Left'
import ProjetForm from './AddProjet';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
function ProjetDetail() {
    
    const idOrganisation = JSON.parse(localStorage.getItem("organisation"))?.id;
    const { id } = useParams();
    const [projet, setProjet] = useState([]);
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const [isEditMode, setIsEditMode] = useState(false);
    const navigate = useNavigate();

    const fetchData = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/projects/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setProjet(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setLoading(false);
        }
    };


    const handleProjetUpdated = (updatedProjet) => {
        setProjet(updatedProjet);
    };

    const handleDeleteProjet = async () => {
        // e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/projects/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            fetchData();
            navigate('/');
            console.log('text');

        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    useEffect(() => {


        fetchData();
    }, [id]);



    if (loading) {
        return (
            <div className="row m-2">
                <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                    <div className="right p-2 mx-2 my-2">
                        <Skeleton height={200} className="mb-2" />
                        <Skeleton height={30} width={150} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                        <Skeleton height={20} width={100} className="mb-2" />
                    </div>
                </div>
                <div className="col-4">
                    <Skeleton height={200} className="mb-2" />
                    <Skeleton height={200} className="mb-2" />
                </div>
            </div>
        );
    }
    return (
        <div className="row m-2">
            <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                <div className="right p-2 mx-2 my-2">

                    {
                        projet.id && <div key={projet.id} className="g-0 card border rounded p-2">
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex pb-2'>
                                    <div className=''>
                                        <img src={FILE_URL + projet.organisation?.logo} className='image-comment rounded-circle' alt='logo organisation' />
                                    </div>

                                    <div className=''>
                                        <div className='non-publica-style '>
                                            {projet.organisation?.nom}
                                        </div>
                                        <div className='date-publica-style'>
                                            {new Date(projet.createdAt).toLocaleDateString()}
                                        </div>
                                    </div>
                                </div>


                                <div className='dropdown'>
                                    <button className="btn-non-border-style" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FontAwesomeIcon icon={faEllipsisVertical} className='font-icon' />
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        {/* <li><Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#addpostModal" onClick={() => setIsEditMode(true)}> Modifier </Link></li> */}
                                        {
                                            idOrganisation === projet.organisation?.id && <>
                                                <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#addProjetModal" onClick={() => setIsEditMode(true)}><FontAwesomeIcon icon={faPenToSquare} className='font-icon px-2' /> Modifier</button></li>
                                                <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#supprojetModal"><FontAwesomeIcon icon={faTrashCan} className='font-icon px-2' /> Supprimer</button></li>

                                            </>
                                        }

                                        <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#supprojetModal"><img src={interested} className='font-icon px-1' alt='...' /> Intéresser</button></li>


                                    </ul>

                                    <div className="modal fade" id="supprojetModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className=" modal-dialog modal-dialog-centered ">
                                            <div className="modal-content">
                                                <div className="modal-header">

                                                    <h5 className='modal-title fs-4'>Supprimer le post</h5>

                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">

                                                    <div className='text-center'>

                                                        <p>Vous êtes sur le point de supprimer ce post. Voulez-vous continuer?</p>


                                                    </div>

                                                    <div className='d-flex justify-content-between align-item-center'>
                                                        <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                        <button className="btn btn-success px-3" type="button" onClick={() => handleDeleteProjet()} data-bs-dismiss="modal"> Oui</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>
                            <h5 className='prj-title'>
                                {projet.titre}
                            </h5>
                            <div className='prj-desc'>
                                {projet.description}
                            </div>

                            <div className='d-flex flex-column flex-md-row justify-content-between mt-3'>
                                <div className='budget mt-3'>
                                    <h6> Budget</h6>
                                    <div>
                                        <span>Maxi: {projet.max}</span>
                                        <span>Min : {projet.min}</span>

                                    </div>
                                </div>

                                <div className='budget mt-3'>
                                    <h6> Deadline</h6>
                                    <div>
                                        <span>{new Date(projet.date).toLocaleDateString()}</span>
                                    </div>

                                </div>
                            </div>
                            {/* <div className='px-2 py-2 d-flex justify-content-start w-100'>
                                <img src={budget} alt='...' />
                                <div className='px-2 txt-bold'>
                                    <span className='txt-bd'>Budget :</span>  Maxi: {projet.max} Min : {projet.min}
                                </div>
                            </div>
                            <div className='px-2 d-flex justify-content-start w-100'>
                                <img src={deadline} alt='...' />
                                <div className='px-2 txt-bold'>
                                    <span className='txt-bd'>deadline : </span>  {new Date(projet.date).toLocaleDateString()}
                                </div>
                            </div> */}

                            {/* <div className='px-2 py-3 d-flex justify-content-between w-100'>
                                <button className='prj-btn d-flex justify-content-center'>
                                    <img src={interested} alt='...' /> <span className='txt-btn px-1'>Intéresser</span>
                                </button>


                            </div> */}

                        </div>
                    }







                </div>


                <ProjetForm projetToEdit={projet} isEditMode={isEditMode} onProjetAdded={() => {/* Rafraîchir la liste des posts */ }} onProjetUpdated={handleProjetUpdated} />

            </div>

            <div className="col-4">
                <Left />

            </div>

        </div>
    )
}

export default ProjetDetail;

