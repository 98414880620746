import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
const FILE_URL = 'https://holilinkv2storage.blob.core.windows.net/main/'; // Remplacez par votre URL de fichier
const API_URL_COMPTES = `${process.env.REACT_APP_API_URL}/api/v1/stories/comptes`; // Remplacez par votre URL d'API pour les comptes
const API_URL_ORGANISATIONS = `${process.env.REACT_APP_API_URL}/api/v1/stories/organisations`; // Remplacez par votre URL d'API pour les organisations

function StatusViewer() {
    const [status, setStatus] = useState(null);
    const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        // Récupérer tous les statuts depuis le backend
        const fetchStatuses = async () => {
            try {
                const [comptesResponse, organisationsResponse] = await Promise.all([
                    axios.get(API_URL_COMPTES, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }),
                    axios.get(API_URL_ORGANISATIONS, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    })
                ]);
                const allStatuses = [...comptesResponse.data, ...organisationsResponse.data];
                // Filtrer le statut par son ID
                const foundStatus = allStatuses.find(st => st.id === id);
                setStatus(foundStatus);
            } catch (error) {
                console.error('Erreur lors de la récupération des statuts:', error);
            }
        };

        fetchStatuses();
    }, [id]);

    useEffect(() => {
        if (status && status.stories.length > 0 && !isPaused) {
            const timer = setInterval(() => {
                setProgress(prevProgress => {
                    if (prevProgress >= 100) {
                        setCurrentStoryIndex(prevIndex => {
                            const nextIndex = prevIndex + 1;
                            if (nextIndex >= status.stories.length) {
                                clearInterval(timer);
                                // handleClose(); // Fermer le modal
                                navigate('/'); // Rediriger vers la page d'accueil
                                localStorage.removeItem('viewedStories');
                                return prevIndex;
                            }
                            return nextIndex;
                        });
                        return 0;
                    }
                    return prevProgress + 1;
                });
            }, 50); // Mettre à jour la progression toutes les 50ms

            return () => clearInterval(timer); // Nettoyer l'intervalle lors du démontage du composant
        }
    }, [status, isPaused, navigate]);

    useEffect(() => {
        setProgress(0); // Réinitialiser la progression à 0 lorsque l'index de la story change
    }, [currentStoryIndex]);

    useEffect(() => {
        if (status) {
            const viewedStories = JSON.parse(localStorage.getItem('viewedStories')) || {};
            const lastViewedIndex = viewedStories[id] || 0;
            if (lastViewedIndex >= status.stories.length) {
                setCurrentStoryIndex(0);
                localStorage.removeItem('viewedStories');
            } else {
                setCurrentStoryIndex(lastViewedIndex);
            }
        }
    }, [status, id]);

    const handlePauseResume = () => {
        setIsPaused(!isPaused);
    };

    const handleNextStory = () => {
        setCurrentStoryIndex(prevIndex => {
            const nextIndex = prevIndex + 1;
            if (nextIndex >= status.stories.length) {
                // handleClose(); // Fermer le modal
                navigate('/'); // Rediriger vers la page d'accueil
                localStorage.removeItem('viewedStories');
                return 0;
            }
            localStorage.setItem('viewedStories', JSON.stringify({ ...JSON.parse(localStorage.getItem('viewedStories')), [id]: nextIndex }));
            return nextIndex;
        });
        setProgress(0);
    };

    const handlePreviousStory = () => {
        setCurrentStoryIndex(prevIndex => {
            const prevIndexAdjusted = prevIndex - 1;
            if (prevIndexAdjusted < 0) {
                return 0;
            }
            localStorage.setItem('viewedStories', JSON.stringify({ ...JSON.parse(localStorage.getItem('viewedStories')), [id]: prevIndexAdjusted }));
            return prevIndexAdjusted;
        });
        setProgress(0);
    };

    const currentStory = status?.stories?.[currentStoryIndex];

    return (
        // <Modal show={show} centered className='styleModal'>
        //     <Modal.Body>
        <div className="status-viewer">
            <div className='container-icon-fermer' >
                <Link to="/">
                    <FontAwesomeIcon className='icon-fermer' icon={faXmarkCircle} />
                </Link>
            </div>
            <div className="progress-bar-container">
                {status?.stories?.map((story, index) => (
                    <div
                        key={story.id}
                        className="progress-bar"
                        style={{
                            width: `${100 / status.stories.length}%`,
                            backgroundColor: 'lightgray',
                            height: '5px',
                            margin: '0 2px',
                            position: 'relative',
                            borderRadius: '0px'
                        }}
                    >
                        {index === currentStoryIndex && (
                            <div
                                className="progress"
                                style={{
                                    width: `${progress}%`,
                                    backgroundColor: 'blue',
                                    height: '100%',
                                    transition: 'width 0.5s linear',
                                    borderRadius: '0px'
                                }}
                            ></div>
                        )}
                    </div>
                ))}
            </div>
            <div className="status-content" onClick={handlePauseResume}>
                {currentStory?.media ? (
                    <img src={FILE_URL + currentStory?.media} alt="Story media" className="story-media" />
                ) : (
                    <div className="story-description" style={{ backgroundColor: currentStory?.color || '#fff' }}>
                        {currentStory?.description}
                    </div>
                )}
            </div>
            {
                status?.stories?.length > 1 && <div className="navigation-icons">
                    <FontAwesomeIcon icon={faArrowLeft} className="nav-icon1" onClick={handlePreviousStory} />
                    <FontAwesomeIcon icon={faArrowRight} className="nav-icon1" onClick={handleNextStory} />
                </div>
            }

        </div>
        //     </Modal.Body>
        // </Modal>
    );
}

export default StatusViewer;