/* eslint-disable jsx-a11y/anchor-is-valid */

import add from '../image/icon-card/add.png'
import logo_site from '../image/logo-site/image.jpg'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import QuestionnaireForm from '../Sondage/AddSondage';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SkeletonLoaderPost from '../SkeletonLoad/skeletonloaderPost';
function SondageAccueil() {
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const [votedSurveys, setVotedSurveys] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('account'));
    const [organisation, setOrganisation] = useState(JSON.parse(localStorage.getItem('organisation')) || '');
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    useEffect(() => {
        // Définir un intervalle qui vérifie la valeur dans le localStorage toutes les secondes
        const intervalId = setInterval(() => {
            const currentOrganisation = localStorage.getItem('organisation') || '';
            if (currentOrganisation !== organisation) {
                setOrganisation(currentOrganisation);
            }
        }, 1000); // Intervalle de 1 seconde

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, [organisation]); // Dépendance à l'état "organisation"


    const VerificatioCompte = (e) => {

        if (!organisation) {
            notify('Veillez changer de compte pour pouvoir créer un Sondage', 'info')
        } else {
            // Ouvre le modal Bootstrap pur avec jQuery ou JavaScript pur
            const scanModal = document.getElementById('addSondageModal');
            const bootstrapModal = new window.bootstrap.Modal(scanModal);
            bootstrapModal.show();
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/surveys`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                // Filtrer les sondages qui ne sont pas clôturés
                const openSurveys = response.data.filter(survey => !survey.cloture);
                // Trier les posts par date de création (du plus récent au plus ancien)
                const sortedSurveys = openSurveys.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                // Sélectionner les six derniers posts
                const latestSurveys = sortedSurveys.slice(0, 6);
                setSurveys(latestSurveys);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching surveys:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleVote = async (surveyId, optionId) => {
        const accessToken = localStorage.getItem('accessToken');
        if (votedSurveys.includes(surveyId)) {
            notify("Vous avez déjà voté pour ce sondage.");
            return;
        }

        // notify("Vote en cours...");

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/surveys/${surveyId}/voting/${optionId}`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            // Mettre à jour l'état local des sondages pour refléter le nouveau vote
            setSurveys(prevSurveys => prevSurveys.map(survey => {
                if (survey.id === surveyId) {
                    return {
                        ...survey,
                        options: survey.options.map(option => {
                            if (option.id === optionId) {
                                return {
                                    ...option,
                                    _count: { votes: option._count.votes + 1 },
                                    votes: [...option.votes, { compte: { id: currentUser.id, photo: currentUser.photo } }]
                                };
                            }
                            return option;
                        })
                    };
                }
                return survey;
            }));

            setVotedSurveys([...votedSurveys, surveyId]);
            notify("Vote effectué avec succès!");
        } catch (error) {
            console.error('Error voting:', error.response.data.message);
            notify(error.response.data.message, 'error');
        }
    };

    const handleSondageAdded = (newData) => {
        setSurveys([newData, ...surveys]);
    };

    const getLastTwoVoters = (votes) => {
        const lastTwoVotes = votes.slice(-2);
        return lastTwoVotes.map(vote => vote.compte.photo);
    };

    const chunkedSurveys = chunkArray(surveys, 2);


    if (loading) {
        return (
            <SkeletonLoaderPost />
        );
    } else if (surveys.length === 0) {
        return (
            <div className="right p-2 mx-2 my-2 h-25 position-relative">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>
                        Sondages
                    </div>
                    <div className='px-2 btn-add' onClick={VerificatioCompte}>
                        <img src={add} className="rounded-start flex-shrink-1" alt="..." />
                    </div>
                </div>
                <div className='w-100 h-75 bg-body-secondary'>
                    <h6 className='position-absolute top-50 start-50 translate-middle text-center'>Aucun Sondage disponible</h6>
                </div>
                <QuestionnaireForm onSondageAdded={handleSondageAdded} />
            </div>
        );
    }

    return (
        <div className="right p-2 mx-2 my-2">
            <div className='d-flex flex-wrap my-2'>
                <div className='px-2 txt-head'>
                    Sondages
                </div>
                <div className='px-2 btn-add' onClick={VerificatioCompte}>
                    <img src={add} className="rounded-start flex-shrink-1" alt="..." />
                </div>
            </div>
            <div id="carouselSondage" className="carousel carousel-dark slide" data-bs-ride="true">
                <div className="carousel-inner">
                    {chunkedSurveys.map((surveyPair, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                            <div className="card-group  grip gap-2 section-container">
                                {surveyPair.map(survey => (
                                    <div key={survey.id} className="g-0 card  card-sondage border1 rounded " style={chunkedSurveys.length === 1 || surveyPair.length === 1 ? { position: 'relative', maxWidth: '20em' } : { position: 'relative', minWidth: '20em', maxWidth: '100vw' }}>
                                        <div className="poll">
                                            <div className="poll-header  d-flex align-items-center">

                                                {
                                                    (survey.couverture) && <img src={`${FILE_URL}${survey.couverture}`} alt={`${survey.publiePar.nom} ${survey.publiePar.prenom}`} className="sondage-image" />
                                                }


                                            </div>
                                            <div className="poll-header d-flex align-items-center">

                                                <h5 className="prj-title ms-2 pt-2 text-truncate" style={{ maxWidth: '100vw' }}>{survey.question}</h5>
                                            </div>
                                            <div className="poll-header d-flex align-items-center py-2">
                                                {
                                                    (survey.multiple) ? <>
                                                        <FontAwesomeIcon icon={faCheckCircle} className='check-icon' />
                                                        <div className='style-info-mutiple-option px-2'>Sélectionnez une ou plusieurs options</div>
                                                    </> : <>
                                                        <FontAwesomeIcon icon={faCheckCircle} className='check-icon' />
                                                        <div className='style-info-mutiple-option px-2'>Sélectionnez une seule option</div>
                                                    </>
                                                }

                                            </div>
                                            {survey.options.slice(0, 3).map(option => (
                                                <div key={option.id} onClick={() => handleVote(survey.id, option.id)}>
                                                    <div className="poll-option  d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                name={`poll-${survey.id}`}
                                                                checked={option.votes.some(vote => vote.compte.id === currentUser.id)}
                                                                style={{ color: option.votes.some(vote => vote.compte.id === currentUser.id) ? '#007bff' : 'inherit' }}
                                                                readOnly
                                                            />
                                                            {option.option}
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div>
                                                                {getLastTwoVoters(option.votes).map((photo, index) => (
                                                                    photo == null ? <img key={index} src={logo_site} alt="voter" className="voter-photo" /> : <img key={index} src={`${FILE_URL}${photo}`} alt="voter" className="voter-photo" />
                                                                ))}
                                                            </div>
                                                            <div>
                                                                {option._count.votes}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="poll-bar poll-bar1">
                                                        <div
                                                            className={`${option._count.votes === 0 ? '' : 'poll-bar-inner'} `}
                                                            style={{
                                                                width: `${(option._count.votes / survey.options.reduce((acc, opt) => acc + opt._count.votes, 0)) * 100}%`,

                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className='mb-5'></div>

                                            <div className='voir' style={{ position: 'absolute', bottom: '0', left: '7.5em', minWidth: '15em', maxWidth: '100vw' }}>

                                                <Link className='nav-link ' to={`/sondage-detail/${survey.id}`}>
                                                    Voir detail
                                                </Link>

                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="carousel-indicators">
                    {chunkedSurveys.map((_, index) => (
                        <button
                            key={index}
                            type="button"
                            data-bs-target="#carouselSondage"
                            data-bs-slide-to={index}
                            className={index === 0 ? 'active' : ''}
                            aria-current={index === 0 ? 'true' : 'false'}
                            aria-label={`Slide ${index + 1}`}
                        ></button>
                    ))}
                </div>
            </div>
            <div className="d-flex w-100 justify-content-center">
                {surveys.length > 2 ? (
                    <div className="d-flex w-100 justify-content-center">
                        <Link to={`/sondage-list`} className='nav-link'>Afficher tous les sondages &rarr;</Link>
                    </div>
                ) : null}
            </div>
            <QuestionnaireForm onSondageAdded={handleSondageAdded} />
            <ToastContainer transition={Zoom} />
        </div >
    );
}

export default SondageAccueil;

const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
};