/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import QuestionnaireForm from '../Sondage/AddSondage';
import Left from '../Accueil/Left';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faPenToSquare, faClock } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import logo_site from '../image/logo-site/image.jpg'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
function SondageDetail() {
    const [survey, setSurvey] = useState([]);
    const { id } = useParams();
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const [votedSurveys, setVotedSurveys] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('account'));
    const [loadingClose, setLoadingClose] = useState(false);
    const navigate = useNavigate();
    const [isEditMode, setIsEditMode] = useState(false);
    const org = JSON.parse(localStorage.getItem('organisation'));
    const [organisation, setOrganisation] = useState(JSON.parse(localStorage.getItem('organisation')) || '');
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    useEffect(() => {
        // Définir un intervalle qui vérifie la valeur dans le localStorage toutes les secondes
        const intervalId = setInterval(() => {
            const currentOrganisation = localStorage.getItem('organisation') || '';
            if (currentOrganisation !== organisation) {
                setOrganisation(currentOrganisation);
            }
        }, 1000); // Intervalle de 1 seconde

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, [organisation]); // Dépendance à l'état "organisation"
    const fetchData = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/surveys/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            // Filtrer les sondages qui ne sont pas clôturés
            // const openSurveys = response.data.filter(survey => !survey.cloture);
            setSurvey(response.data);
            console.log(response.data)
        } catch (error) {
            console.error('Error fetching surveys:', error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleDeleteSondage = async () => {
        // e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/surveys/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            fetchData(); // Refresh comments after deletion
            navigate('/');
            console.log('text');

        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const clotureSondage = async (e) => {

        const accessToken = localStorage.getItem('accessToken');
        setLoadingClose(true);
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/surveys/${e}/voting/close`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            setLoadingClose(false);
            fetchData(); // Refresh comments after deletion
            navigate('/');
        } catch (error) {
            console.error('Error closing surveys:', error);
        }

    }


    const handleVote = async (surveyId, optionId) => {
        const accessToken = localStorage.getItem('accessToken');
        if (votedSurveys.includes(surveyId)) {
            notify("Vous avez déjà voté pour ce sondage.");
            return;
        }


        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/surveys/${surveyId}/voting/${optionId}`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            // Mettre à jour l'état local des sondages pour refléter le nouveau vote
            setSurvey(prevSurveys => prevSurveys.map(survey => {
                if (survey.id === surveyId) {
                    return {
                        ...survey,
                        options: survey.options.map(option => {
                            if (option.id === optionId) {
                                return {
                                    ...option,
                                    _count: { votes: option._count.votes + 1 },
                                    votes: [...option.votes, { compte: { id: currentUser.id, photo: currentUser.photo } }]
                                };
                            }
                            return option;
                        })
                    };
                }
                return survey;
            }));

            setVotedSurveys([...votedSurveys, surveyId]);
            notify("Vote effectué avec succès!");
            // setModalMessage('Votre vote a été pris en compte.');
        } catch (error) {
            console.error('Error voting:', error.response.data.message);
            // setModalMessage(error.response.data.message);
            notify(error.response.data.message);
        }
    };

    const handleSondageAdded = (newData) => {
        setSurvey([newData, ...survey]);
    };

    const getLastTwoVoters = (votes) => {
        const lastTwoVotes = votes.slice(-2);
        return lastTwoVotes.map(vote => vote.compte.photo);
    };
    const handleSondageUpdated = (updatedPost) => {
        setSurvey(updatedPost);
    };
    return (
        <div className='row m-md-1'>
            <div className='col-2'></div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                <div key={survey?.id} className="right p-2 mx-2 my-2" >

                    <div className='d-flex justify-content-between'>
                        <div className='d-flex pb-2'>
                            <div className=''>
                                <img src={FILE_URL + survey?.organisation?.logo} className='image-comment rounded-circle' alt='logo organisation' />
                            </div>

                            <div className=''>
                                <div className='non-publica-style '>
                                    {survey?.organisation?.nom}
                                </div>
                                <div className='date-publica-style'>
                                    {new Date(survey.createdAt).toLocaleDateString()}
                                </div>
                            </div>

                        </div>

                        {
                            org?.id === survey?.organisation?.id && (<div className='dropdown'>
                                <button className="btn-non-border-style" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FontAwesomeIcon icon={faEllipsisVertical} className='font-icon' />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    {/* <li><Link className="dropdown-item" data-bs-toggle="modal" data-bs-target="#addpostModal" onClick={() => setIsEditMode(true)}> Modifier </Link></li> */}

                                    <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#addSondageModal" onClick={() => setIsEditMode(true)}><FontAwesomeIcon icon={faPenToSquare} className='font-icon px-2' /> Modifier</button></li>
                                    <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' onClick={() => clotureSondage(survey.id)} disabled={loadingClose} >

                                        <FontAwesomeIcon icon={faClock} className='icon-seting' />
                                        <Link class="nav-link" to="#">Cloturer</Link>
                                    </li>
                                    <li><button className="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#supSondageModal"><FontAwesomeIcon icon={faTrashCan} className='font-icon px-2' /> Supprimer</button></li>

                                </ul>

                                <div className="modal fade" id="supSondageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div className=" modal-dialog modal-dialog-centered ">
                                        <div className="modal-content">
                                            <div className="modal-header">

                                                <h5 className='modal-title fs-4'>Supprimer le Sondage</h5>

                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body">

                                                <div className='text-center'>

                                                    <p>Vous êtes sur le point de supprimer ce Sondage. Voulez-vous continuer?</p>


                                                </div>

                                                <div className='d-flex justify-content-between align-item-center'>
                                                    <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                    <button className="btn btn-success px-3" type="button" onClick={() => handleDeleteSondage()} data-bs-dismiss="modal"> Oui</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }



                    </div>
                    
                    <div className="poll">
                        <div className=" ">

                            {
                                (survey?.couverture) && <img src={`${FILE_URL}${survey?.couverture}`} alt={`${survey?.publiePar?.nom} ${survey?.publiePar?.prenom}`} className="sondage-image-descri" />
                            }


                        </div>
                        <div className="poll-header d-flex align-items-center">
                            {/* <div>
                                                <img src={`${FILE_URL}${survey.publiePar.photo}`} alt={`${survey.publiePar.nom} ${survey.publiePar.prenom}`} className="img-fluid" />
                                            </div> */}
                            <h5 className="ms-2 pt-2 text-truncate" >{survey?.question}</h5>
                        </div>
                        <div className="poll-header d-flex align-items-center py-2">
                            {
                                (survey?.multiple) ? <>
                                    <FontAwesomeIcon icon={faCheckCircle} className='check-icon' />
                                    <div className='style-info-mutiple-option px-2'>Sélectionnez une ou plusieurs options</div>
                                </> : <>
                                    <FontAwesomeIcon icon={faCheckCircle} className='check-icon' />
                                    <div className='style-info-mutiple-option px-2'>Sélectionnez une seule option</div>
                                </>
                            }

                        </div>
                        {survey?.options?.map(option => (
                            <div key={option.id} onClick={() => handleVote(survey.id, option.id)}>
                                <div className="poll-option d-flex justify-content-between align-items-center">
                                    <div>
                                        <input
                                            type="checkbox"
                                            name={`poll-${survey.id}`}
                                            checked={option.votes.some(vote => vote.compte.id === currentUser.id)}
                                            style={{ color: option.votes.some(vote => vote.compte.id === currentUser.id) ? '#007bff' : 'inherit' }}
                                            readOnly
                                        />
                                        {option.option}
                                    </div>
                                    <div className='d-flex'>
                                        <div>
                                            {getLastTwoVoters(option.votes).map((photo, index) => (
                                                // <img key={index} src={`${FILE_URL}${photo}`} alt="voter" className="voter-photo" />
                                                photo == null ? <img key={index} src={logo_site} alt="voter" className="voter-photo" /> : <img key={index} src={`${FILE_URL}${photo}`} alt="voter" className="voter-photo" />
                                            ))}
                                        </div>
                                        <div>
                                            {option._count.votes}
                                        </div>
                                    </div>
                                </div>
                                <div className="poll-bar poll-bar1">
                                    <div
                                        className={`${option._count.votes === 0 ? '' : 'poll-bar-inner'} `}
                                        style={{
                                            width: `${(option._count.votes / survey.options.reduce((acc, opt) => acc + opt._count.votes, 0)) * 100}%`,

                                        }}
                                    ></div>
                                </div>
                            </div>
                        ))}
                        

                        <QuestionnaireForm  isEditMode={isEditMode} onSondageAdded={() => {/* Rafraîchir la liste des posts */ }} onSondageUpdated={handleSondageUpdated} sondageToEdit={survey} />
                    </div>



                </div>
            </div>
            <div className='col-4'>
                <Left />
            </div>
        </div>
    );
}

export default SondageDetail;