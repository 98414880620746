/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable react-hooks/exhaustive-deps */
import Left from "../Accueil/Left";
import logo_site from '../image/logo-site/image.jpg'
import entreprise from '../image/Organisation-logo/entreprise.png'
import email from '../image/Organisation-logo/email.png'
import phone from '../image/Organisation-logo/phone.png'
import web from '../image/Organisation-logo/web.png'
import comment from '../image/icon-card/commentaire.png'
import aime from '../image/icon-card/aime.png'
import non_aime from '../image/icon-card/pas-aime.png'
import view from '../image/vue.png'
import intDepartement from '../image/ajouter-un-ami.png'
import date from '../image/icon-card/date.png'
import location from '../image/icon-card/location.png'
import budget from '../image/icon-card/budget.png'
import deadline from '../image/icon-card/deadline.png'
import interested from '../image/icon-card/interested.png'
import { Modal, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';

import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import OrganizationForm from "./AddOrganisation";
import StatusViewer from "../Status/StatusViewer";
function OrganisationDetail() {
    const [activeTab, setActiveTab] = useState('Apropos');
    const { id } = useParams();
    const [organization, setOrganization] = useState([]);
    // const [organ, setOrgan] = useState([]);
    const [loading, setLoading] = useState(true);
    const [offices, setOffices] = useState([]);
    const [selectedOfficeId, setSelectedOfficeId] = useState(null);
    const [selectedOfficeNom, setSelectedOfficeNom] = useState(null);
    const [officeDetails, setOfficeDetails] = useState(null);
    const [services, setServices] = useState([]);
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [serviceDetails, setServiceDetails] = useState(null);
    const [posts, setPosts] = useState([]);
    const [statuts, setStatuts] = useState([]);
    const [evenements, setEvenements] = useState([]);
    const [projets, setProjets] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalStatut, setShowModalStatut] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const [votedSurveys, setVotedSurveys] = useState([]);
    const currentUser = JSON.parse(localStorage.getItem('account'));
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [showContent, setShowContent] = useState(true);
    const [countries, setCountries] = useState([]);
    const [showModalCountry, setShowModalCountry] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errors, setErrors] = useState({});
    const [loadingDepartement, setLoadingDepartement] = useState(false);
    const [loadingService, setLoadingService] = useState(false);
    const [loadingAddDepartement, setLoadingAddDepartement] = useState(false);
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new window.bootstrap.Popover(popoverTriggerEl))
    const [idStatus, setIdStatus] = useState('');
    const handleShow = () => setShowModalStatut(true);
    const handleClose = () => setShowModal(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [item, setItem] = useState([]);
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/countries`);
                setCountries(response.data);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);

    const handleCountrySelect = (country) => {
        setSelectedCountry(country);
        setShowModalCountry(false);
    };
    const handlePhoneChange = (e) => {
        setPhoneNumber(e.target.value);
    };



    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/surveys/organisations/${id}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                // Filtrer les sondages qui ne sont pas clôturés
                const openSurveys = response.data.filter(survey => !survey.cloture);
                setSurveys(openSurveys);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching surveys:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleVote = async (surveyId, optionId) => {
        const accessToken = localStorage.getItem('accessToken');
        if (votedSurveys.includes(surveyId)) {
            notify("Vous avez déjà voté pour ce sondage.");
            return;
        }

        // setModalMessage('Vote en cours...');
        // setShowModal(true);

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/surveys/${surveyId}/voting/${optionId}`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });

            // Mettre à jour l'état local des sondages pour refléter le nouveau vote
            setSurveys(prevSurveys => prevSurveys.map(survey => {
                if (survey.id === surveyId) {
                    return {
                        ...survey,
                        options: survey.options.map(option => {
                            if (option.id === optionId) {
                                return {
                                    ...option,
                                    _count: { votes: option._count.votes + 1 }
                                };
                            }
                            return option;
                        })
                    };
                }
                return survey;
            }));

            setVotedSurveys([...votedSurveys, surveyId]);
            notify("Vote effectué avec succès!");
        } catch (error) {
            console.error('Error voting:', error.response.data.message);
            notify(error.response.data.message, 'error');
        }
    };
    const handleTabClick = (tab) => {
        setActiveTab(tab);

    };
    useEffect(() => {
        if (activeTab === 'Publication') {
            const accessToken = localStorage.getItem('accessToken');
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/posts/organisations/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    setPosts(response.data);

                })
                .catch(error => {
                    console.error('There was an error fetching the post!', error);
                });
        }
    }, [activeTab]);

    useEffect(() => {
        if (activeTab === 'Publication') {
            const accessToken = localStorage.getItem('accessToken');
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/stories/organisations/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    setStatuts(response.data);
                    console.log(response.data)

                })
                .catch(error => {
                    console.error('There was an error fetching the statut!', error);
                });
        }
    }, [activeTab]);

    useEffect(() => {
        if (activeTab === 'Publication') {
            const accessToken = localStorage.getItem('accessToken');
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/projects/organisations/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    setProjets(response.data);

                })
                .catch(error => {
                    console.error('There was an error fetching the projets!', error);
                });
        }
    }, [activeTab]);

    useEffect(() => {
        if (activeTab === 'Publication') {
            const accessToken = localStorage.getItem('accessToken');
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/events/organisations/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    setEvenements(response.data);

                })
                .catch(error => {
                    console.error('There was an error fetching the evenement!', error);
                });
        }
    }, [activeTab]);




    const getServices = () => {
        if (activeTab === 'Services') {
            const accessToken = localStorage.getItem('accessToken');
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/services/organisation/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    setServices(response.data);
                    if (response.data.length > 0) {
                        setSelectedServiceId(response.data[0].id);
                        setServiceDetails(
                            {
                                'nom': response.data[0].nom,
                                'description': response.data[0].description
                            }
                        )
                    }
                })
                .catch(error => {
                    console.error('There was an error fetching the service!', error);
                });
        }
    }


    const getOffices = () => {
        if (activeTab === 'Offices') {
            const accessToken = localStorage.getItem('accessToken');
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/offices/organisation/${id}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    setOffices(response.data);
                    if (response.data.length > 0) {
                        setSelectedOfficeId(response.data[0].id);

                    }
                })
                .catch(error => {
                    console.error('There was an error fetching the offices!', error);
                });
        }
    }
    const handleDeleteDepartement = async (e) => {
        // e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/offices/${e}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            getOffices(); // Refresh comments after deletion
            // navigate('/');
            notify("Le departement est Bien Supprimer !");
            console.log('text');

        } catch (error) {
            console.error('Error deleting comment:', error);
            notify("Une erreur s'est produite lors de la suppression du departement !", 'error');
        }
    };
    useEffect(() => {
        getOffices();
        getServices();
    }, [activeTab]);

    useEffect(() => {
        if (selectedOfficeId) {
            console.log(selectedOfficeId);
            const accessToken = localStorage.getItem('accessToken');
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/offices/${selectedOfficeId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
                .then(response => {
                    console.log('detail office', response.data)
                    setOfficeDetails(response.data);
                })
                .catch(error => {
                    console.error('There was an error fetching the office details!', error);
                });
        }
    }, [selectedOfficeId]);


    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            // console.log("voici l'id", id);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/organizations`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                setOrganization(response.data);
                response.data.map(ite => {
                    if (ite.id === id) {
                        setItem(ite)
                    }
                })
                console.log(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };


        fetchData();
    }, []);

    const getLastTwoVoters = (votes) => {
        const lastTwoVotes = votes.slice(-2);
        return lastTwoVotes.map(vote => vote.compte.photo);
    };
    const AbonnerOrganisation = (e) => {
        const accessToken = localStorage.getItem('accessToken');
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/organizations/${e}/subscribe`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        }).then(response => response.json())
            .then(data => {
                console.log('Success Abonnement organisation:', data);
                notify("Abonnement réussi !");
                // setLoading(false);
            })
            .catch((error) => {
                console.error('ErrorAbonnement:', error);
                notify("Une erreur s'est produite lors de votre abonnement!", 'error');
                // setLoading(false);
            });

    }

    // const [idOrganisation, setIdOrganisation] = useState('');
    const [formDataUserToDepartement, setFormDataUserToDepartement] = useState({
        poste: ''
    });

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setFormDataUserToDepartement({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };


    const validateForm1 = () => {
        const newErrors = {};
        if (!formDataUserToDepartement.poste) newErrors.poste = 'Le role est requis';


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const AddUserOnDepartement = (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        if (!validateForm1()) return;
        setLoadingAddDepartement(true);

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/offices/${selectedOfficeId}/member`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                telephone: phoneNumber,
                role: formDataUserToDepartement.poste,
                country: selectedCountry.id

            })

        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                if (data.code) {
                    notify(data.message, 'error');

                } else {
                    notify("La personne est bien integré dans le departement selectionné !");
                    getOffices()

                }
                setLoadingAddDepartement(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                notify("Une erreur s'est produite lors de l'ajout du département!", 'error');
                setLoadingAddDepartement(false);
            });
    };

    const [formData, setFormData] = useState({
        nom: '',
        description: '',
        organisationId: id
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };


    const validateForm = () => {
        const newErrors = {};
        if (!formData.nom) newErrors.nom = 'Le nom est requis';
        if (!formData.description) newErrors.description = 'La description est requise';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const AjouterDepartement = (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        if (!validateForm()) return;
        setLoadingDepartement(true);

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/offices`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                nom: formData.nom,
                description: formData.description,
                organisationId: id

            })

        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                notify("Département ajouté avec succès !");
                getOffices()
                setLoadingDepartement(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                notify("Une erreur s'est produite lors de l'ajout du département!", 'error');
                setLoadingDepartement(false);
            });
    };


    const [formDataService, setFormDataService] = useState({
        nom: '',
        description: '',
        organisationId: id
    });

    const handleChangeService = (e) => {
        const { name, value } = e.target;
        setFormDataService({
            ...formDataService,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };


    const validateFormService = () => {
        const newErrors = {};
        if (!formDataService.nom) newErrors.nom = 'Le nom est requis';
        if (!formDataService.description) newErrors.description = 'La description est requise';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const AjouterService = (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        if (!validateFormService()) return;
        setLoadingService(true);

        fetch(`${process.env.REACT_APP_API_URL}/api/v1/services`, {

            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                nom: formDataService.nom,
                description: formDataService.description,
                organisationId: id

            })

        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                notify("Service ajouté avec succès !");
                getServices()
                setLoadingService(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                notify("Une erreur s'est produite lors de l'ajout du Service!", 'error');
                setLoadingService(false);
            });
    };

    const handleOrganisationUpdated = (updatedProjet) => {
        setItem(updatedProjet);
    };


    // const chunkedPosts = chunkArray(posts, 2);

    const [isSubscribe, setIsSubscribe] = useState(false)
    const handleSubscribe = async () => {

        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/organizations/subcribe`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            // Trier les organisation par date de création (du plus récent au plus ancien)
            response.data.map(org => {
                if (org.id === id) {
                    setIsSubscribe(true)
                } else {
                    setIsSubscribe(false)
                }
            })
        } catch (error) {
            console.error('Error fetching organisation:', error);

        }
    }
    useEffect(() => {
        // Simulate loading
        // const intervalId = setInterval(() => {
        handleSubscribe()
        //   }, 1000); // Intervalle de 1 seconde

        // Nettoyer l'intervalle lorsque le composant est démonté
        //   return () => clearInterval(intervalId);
        // setTimeout(() => handleSubscribe(), 1000);
    }, []);



    if (loading) {
        return (
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                <Skeleton height={200} className="mb-2" />
                <Skeleton circle={true} height={100} width={100} className="mb-2" />
                <Skeleton height={30} width={150} className="mb-2" />

            </div>
        );
    }
    return (
        <div className="row m-1 ">

            <div className="col-12 col-sm-12 col-md-12 col-lg-8" >








                <div className=" mx-0 mx-sm-2 mx-md-1 mx-lg-1 my-2" key={item.id}>
                    <div className="couvercle bg-white">
                        <img src={FILE_URL + item.couverture} className='image-detail-post1' alt="..." />
                    </div>
                    <div className='d-flex'>
                        <div className="profil">
                            <img src={FILE_URL + item.logo} className='image-detail-logo' alt="..." />

                        </div>



                    </div>




                    <div className="w-100 tabs before-body1">
                        <ul class="w-100 nav nav-pills mb-3 d-flex justify-content-between" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button className={`nav-link butn ${activeTab === 'Apropos' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Apropos')} >Apropos</button>
                            </li>

                            <li class="nav-item" role="presentation">
                                <button className={`nav-link butn ${activeTab === 'Offices' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Offices')}>Offices</button>
                            </li>

                            <li class="nav-item" role="presentation">
                                <button className={`nav-link butn ${activeTab === 'Publication' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Publication')}>Publication</button>
                            </li>

                            <li class="nav-item" role="presentation">
                                <button className={`nav-link butn ${activeTab === 'Services' ? 'active' : ''}`}
                                    onClick={() => handleTabClick('Services')}>Services</button>
                            </li>
                        </ul>

                    </div>
                    {
                        currentUser.id === item.proprietaireId ? <div className="w-100 d-flex justify-content-end">
                            <button type='button' className='modifier-icon-organisation btn mx-3' data-bs-toggle="modal" data-bs-target="#addOrganisation" onClick={() => { setIsEditMode(true) }}>
                                <FontAwesomeIcon icon={faPenToSquare} className='icon-org' />
                            </button>
                            <button type='button' className='modifier-icon-organisation btn mx-3'>

                                <FontAwesomeIcon icon={faTrashCan} className='icon-org-sup' />
                            </button>

                        </div> : <div className="w-100 d-flex justify-content-end">
                            <button type='button' onClick={() => { AbonnerOrganisation(item?.id); handleSubscribe(); }} className='modifier-icon-organisation btn mx-3'>
                                {
                                    isSubscribe ? <span>Se désabonner</span> : <span>S'abonner</span>
                                }

                            </button>
                            <ToastContainer transition={Zoom} />
                        </div>
                    }





                    <div className="row mx-0 mx-sm-1 mx-md-0 mx-lg-0">

                        <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div className="tab-content mt-2">
                                {activeTab === 'Publication' && (
                                    <div class="tab-pane fade show active">
                                        <div className="left pb-2">


                                            <div class="nav flex-column nav-pills mx-2 " id="v-pills-tab" role="tablist" aria-orientation="vertical">

                                                <button class="nav-link active butn1" id="v-pills-Post-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Post" type="button" role="tab" aria-controls="v-pills-Post" aria-selected="true">Posts</button>
                                                <button class="nav-link butn1" id="v-pills-Statut-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Statut" type="button" role="tab" aria-controls="v-pills-Statut" aria-selected="false">Statuts</button>
                                                <button class="nav-link butn1" id="v-pills-Evenement-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Evenement" type="button" role="tab" aria-controls="v-pills-Evenement" aria-selected="false">Événements</button>
                                                <button class="nav-link butn1" id="v-pills-Projet-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Projet" type="button" role="tab" aria-controls="v-pills-Projet" aria-selected="false">Projets</button>
                                                <button class="nav-link butn1" id="v-pills-Sondage-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Sondage" type="button" role="tab" aria-controls="v-pills-Sondage" aria-selected="false">Sondages</button>
                                            </div>

                                        </div>
                                    </div>

                                )}

                                {activeTab === 'Offices' && (
                                    <div className="tab-pane fade show active">
                                        <div className="left pb-2">

                                            {


                                                currentUser.id === item.proprietaireId &&
                                                <div className="d-flex justify-content-end w-100">
                                                    <button className="add-departement-btn-style" data-bs-toggle="modal" data-bs-target="#AjoutDepartementModal">
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </button>
                                                    <div class="modal fade" id="AjoutDepartementModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                        <div class="modal-dialog">
                                                            <div class="modal-content">
                                                                <div class="modal-header d-flex justify-content-between p-2">
                                                                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Créer un departement</h1>
                                                                    <button type="button" className='button-close-style' data-bs-dismiss="modal" aria-label="Close">
                                                                        <FontAwesomeIcon icon={faXmark} />
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">

                                                                    <form onSubmit={AjouterDepartement} >

                                                                        <div className='mb-3'>

                                                                            <label htmlFor="nom" class="form-label">Nom *</label>
                                                                            <input type="text" className={`form-control ${errors.nom ? 'is-invalid' : ''}`} id="nom" name="nom" value={formData.nom} onChange={handleChange} placeholder="Veuillez saisir le nom" />
                                                                            {errors.nom && <div className="invalid-feedback">{errors.nom}</div>}

                                                                        </div>


                                                                        <div className="mb-3">
                                                                            <label htmlFor="description" className="form-label">Description</label>
                                                                            <textarea id="description" className={`form-control ${errors.description ? 'is-invalid' : ''}`} name="description" value={formData.description} onChange={handleChange} required placeholder="Veuillez saisir la description de votre evenement"></textarea>
                                                                            {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                                                                        </div>

                                                                        <div className="w-100 d-flex justify-content-end">
                                                                            <button className='btn-ad' type="submit" disabled={loadingDepartement}>
                                                                                {loadingDepartement ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Ajouter'}
                                                                            </button>

                                                                        </div>

                                                                    </form>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            }

                                            <div className="nav flex-column nav-pills mx-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                {offices.map((office, index) => (
                                                    <div className="d-flex justify-content-between w-100">
                                                        <button
                                                            key={office.id}
                                                            className={`nav-link butn1 ${selectedOfficeId === office.id ? 'active' : ''}`}
                                                            id={`v-pills-${office.nom}-tab`}
                                                            style={{ maxWidth: '100%', hyphens: 'auto', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                                                            data-bs-toggle="pill"
                                                            data-bs-target={`#v-pills-${office.nom}`}
                                                            type="button"
                                                            role="tab"
                                                            aria-controls={`v-pills-${office.nom}`}
                                                            aria-selected={selectedOfficeId === office.id ? 'true' : 'false'}
                                                            onClick={() => {
                                                                setSelectedOfficeId(office.id);
                                                                setSelectedOfficeNom(office.nom)
                                                            }}
                                                        >
                                                            {office.nom}
                                                        </button>
                                                        {
                                                            currentUser.id === item.proprietaireId && <div className="d-flex justify-content-end">
                                                                <button type='button' className=' btn mx-1' data-bs-toggle="modal" data-bs-target="#addOrganisation" onClick={() => { setIsEditMode(true) }}>
                                                                    <FontAwesomeIcon icon={faPenToSquare} className='' />
                                                                </button>
                                                                <button type='button' className=' btn mx-1' data-bs-toggle="modal" data-bs-target="#supOfficeModal">

                                                                    <FontAwesomeIcon icon={faTrashCan} className='' />
                                                                </button>

                                                                <div className="modal fade" id="supOfficeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                    <div className=" modal-dialog modal-dialog-centered ">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">

                                                                                <h5 className='modal-title fs-4'>Supprimer le Departement</h5>

                                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                            </div>
                                                                            <div className="modal-body">

                                                                                <div className='text-center'>

                                                                                    <p>Vous êtes sur le point de supprimer ce Departement. Voulez-vous continuer?</p>


                                                                                </div>

                                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                                    <button type="button" class="btn btn-danger px-5" data-bs-dismiss="modal">Non</button>
                                                                                    <button className="btn btn-success px-5" type="button" onClick={() => handleDeleteDepartement(office.id)} data-bs-dismiss="modal"> Oui</button>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        }

                                                    </div>

                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {activeTab === 'Services' && (
                                    <div class="tab-pane fade show active">
                                        <div className="left pb-2">

                                            {


                                                currentUser.id === item.proprietaireId &&
                                                <div className="d-flex justify-content-end w-100">
                                                    <button className="add-departement-btn-style" data-bs-toggle="modal" data-bs-target="#AjoutServiceModal">
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </button>
                                                    <div class="modal fade" id="AjoutServiceModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                        <div class="modal-dialog">
                                                            <div class="modal-content">
                                                                <div class="modal-header d-flex justify-content-between p-2">
                                                                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Créer un Service</h1>
                                                                    <button type="button" className='button-close-style' data-bs-dismiss="modal" aria-label="Close">
                                                                        <FontAwesomeIcon icon={faXmark} />
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">

                                                                    <form onSubmit={AjouterService} >

                                                                        <div className='mb-3'>

                                                                            <label htmlFor="nom" class="form-label">Nom *</label>
                                                                            <input type="text" className={`form-control ${errors.nom ? 'is-invalid' : ''}`} id="nom" name="nom" value={formDataService.nom} onChange={handleChangeService} placeholder="Veuillez saisir le nom" />
                                                                            {errors.nom && <div className="invalid-feedback">{errors.nom}</div>}

                                                                        </div>


                                                                        <div className="mb-3">
                                                                            <label htmlFor="description" className="form-label">Description</label>
                                                                            <textarea id="description" className={`form-control ${errors.description ? 'is-invalid' : ''}`} name="description" value={formDataService.description} onChange={handleChangeService} required placeholder="Veuillez saisir la description"></textarea>
                                                                            {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                                                                        </div>

                                                                        <div className="w-100 d-flex justify-content-end">
                                                                            <button className='btn-ad' type="submit" disabled={loadingService}>
                                                                                {loadingService ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Ajouter'}
                                                                            </button>

                                                                        </div>

                                                                    </form>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                            }


                                            <div className="nav flex-column nav-pills mx-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                {services.map((service, index) => (
                                                    <button
                                                        key={service.id}
                                                        className={`nav-link butn1 ${selectedServiceId === service.id ? 'active' : ''}`}
                                                        id={`v-pills-${service.nom}-tab`}
                                                        data-bs-toggle="pill"
                                                        data-bs-target={`#v-pills-${service.nom}`}
                                                        type="button"
                                                        role="tab"
                                                        aria-controls={`v-pills-${service.nom}`}
                                                        aria-selected={selectedServiceId === service.id ? 'true' : 'false'}
                                                        onClick={() => {
                                                            setSelectedServiceId(service.id);
                                                            setServiceDetails({
                                                                'nom': service.nom,
                                                                'description': service.description
                                                            })
                                                        }}
                                                    >
                                                        {service.nom}
                                                    </button>
                                                ))}
                                            </div>



                                        </div>
                                    </div>

                                )}

                            </div>


                        </div>

                        <div className="mt-2 mt-md-0 col-12 col-sm-12 col-md-12 col-lg-8 right my-0 my-sm-0 mx-md-0 my-lg-2  py-0 tab-content" id="pills-tabContent">
                            {activeTab === 'Apropos' && (

                                <div class="tab-pane fade show active">
                                    {
                                        organization.map((item, index) => {
                                            if (id === item.id) {
                                                return (
                                                    <div key={item.id} className="g-0 card  rounded py-2">



                                                        <div className='d-flex align-content-between flex-wrap'>
                                                            <div className='px-1'>
                                                                <div class="title">{item.nom}</div>
                                                                <div class="text">{item.apropos}</div>
                                                            </div>
                                                            <div class="w-100 px-2">

                                                                <div className='pt-2 d-flex justify-content-start w-100'>
                                                                    <img src={entreprise} className='rounded-circle img-org' alt='...' />
                                                                    <div className='px-2'>
                                                                        {item.domaine}
                                                                    </div>
                                                                </div>
                                                                <div className=' pt-2 d-flex justify-content-start w-100'>
                                                                    <img src={phone} className='rounded-circle img-org' alt='...' />
                                                                    <div className='px-2 cursor'>
                                                                        <a href={`tel:${item.phone}`} className="call-to-action">
                                                                            {item.phone}
                                                                        </a>

                                                                    </div>
                                                                </div>
                                                                <div className=' pt-2 d-flex justify-content-start w-100'>
                                                                    <img src={email} className='rounded-circle img-org' alt='...' />
                                                                    <div className='px-2'>

                                                                        <a href={`mailto:${item.email}`} className="call-to-action">
                                                                            {item.email}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div className=' pt-2 d-flex justify-content-start w-100'>
                                                                    <img src={web} className='rounded-circle img-org' alt='...' />
                                                                    <div className='px-2'>

                                                                        <a href={`https://${item.site}`} className="call-to-action" target="_blank">
                                                                            {item.site}
                                                                        </a>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }

                                </div>
                            )}

                            {activeTab === 'Offices' && (
                                <div class="tab-pane fade show active">

                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div className="w-100">
                                            {
                                                currentUser.id === item.proprietaireId && <div className="d-flex justify-content-between my-2">

                                                    <button type='button' className='style-buton-on-office px-2' data-bs-toggle="modal" data-bs-target="#IntegrerDepartement">
                                                        <img src={intDepartement} alt="integrer departement" width="25" height="25" />
                                                    </button>

                                                </div>
                                            }


                                            <div class="modal fade" id="IntegrerDepartement" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div class="modal-dialog">
                                                    <div class="modal-content">
                                                        <div class="modal-header d-flex justify-content-between p-2">
                                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Integrer un Département</h1>
                                                            <button type="button" className='button-close-style' data-bs-dismiss="modal" aria-label="Close">
                                                                <FontAwesomeIcon icon={faXmark} />
                                                            </button>
                                                        </div>
                                                        {
                                                            (showContent) ? <div class="modal-body body-content">
                                                                Souhaitez vous inviter un utilisateur à integrer le departement {(officeDetails) && officeDetails.nom}
                                                                <div class="d-flex justify-content-between">
                                                                    <button type="button" class="btn btn-danger px-3" style={{ width: '10vw' }} data-bs-dismiss="modal">Non</button>
                                                                    <button type="button" class="btn btn-success px-3" style={{ width: '10vw' }} onClick={() => setShowContent(false)} >Oui</button>
                                                                </div>
                                                            </div> : <div class="modal-body ">
                                                                <form onSubmit={AddUserOnDepartement} >
                                                                    <div className="input-group">
                                                                        <label htmlFor="indicatif" class="form-label"> Indicatif </label>
                                                                        <label htmlFor="telephone" class="form-label mx-3"> Téléphone </label>
                                                                    </div>
                                                                    <div className="input-group mb-3">
                                                                        <input
                                                                            type="text"
                                                                            id="countryCode"
                                                                            className="form-control select-container"
                                                                            placeholder="indicatif"
                                                                            value={selectedCountry ? selectedCountry.code : ''}
                                                                            onClick={() => setShowModalCountry(true)}
                                                                            readOnly
                                                                        />
                                                                        <input
                                                                            type="tel"
                                                                            required
                                                                            id="phoneNumber"
                                                                            className="form-control select-container1"
                                                                            placeholder="70 70 70 70"
                                                                            pattern="[0-9]*"
                                                                            value={phoneNumber}
                                                                            onChange={handlePhoneChange}
                                                                        />

                                                                    </div>
                                                                    <div className='mb-3'>

                                                                        <label htmlFor="poste" class="form-label">Poste</label>
                                                                        <input type="text" className={`form-control ${errors.poste ? 'is-invalid' : ''}`} id="poste" name="poste" value={formDataUserToDepartement.poste} onChange={handleChange1} placeholder="Veuillez saisir le poste" />
                                                                        {errors.poste && <div className="invalid-feedback">{errors.poste}</div>}

                                                                    </div>

                                                                    <div class=" d-flex justify-content-end">
                                                                        <button className='btn-ad' type="submit" disabled={loadingAddDepartement}>
                                                                            {loadingAddDepartement ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Ajouter'}
                                                                        </button>
                                                                    </div>
                                                                </form>

                                                                <Modal show={showModalCountry} onHide={() => setShowModalCountry(false)}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Select Country</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <ul className="list-group">
                                                                            {countries.map((country) => (
                                                                                <li
                                                                                    key={country.id}
                                                                                    className="list-group-item"
                                                                                    onClick={() => handleCountrySelect(country)}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >
                                                                                    {country.nom} ({country.code})
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </Modal.Body>

                                                                </Modal>
                                                            </div>
                                                        }



                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                        {officeDetails && (
                                            <div className={`tab-pane d-flex justify-content-center fade show ${selectedOfficeNom === officeDetails.nom ? 'active' : ''}`} id={`v-pills-${officeDetails.nom}`} role="tabpanel" aria-labelledby={`v-pills-${officeDetails.nom}-tab`} tabIndex="0">
                                                <div className="w-100">

                                                    <ul class="nav nav-tabs d-flex justify-content-between" id="myTab" role="tablist">
                                                        <li class="nav-item" role="presentation">
                                                            <button class="nav-link active" id="membre-tab" data-bs-toggle="tab" data-bs-target="#membre-tab-pane" type="button" role="tab" aria-controls="membre-tab-pane" aria-selected="true">Membres</button>
                                                        </li>
                                                        <li class="nav-item" role="presentation">
                                                            <button class="nav-link" id="client-tab" data-bs-toggle="tab" data-bs-target="#client-tab-pane" type="button" role="tab" aria-controls="client-tab-pane" aria-selected="false">Clients</button>
                                                        </li>
                                                    </ul>

                                                    <div class="tab-content pt-2" id="myTabContent">
                                                        <div class="tab-pane fade show active" id="membre-tab-pane" role="tabpanel" aria-labelledby="membre-tab" tabindex="0">
                                                            <div class="container">
                                                                <div class="row">
                                                                    {
                                                                        Array.isArray(officeDetails.membres) && officeDetails.membres.map(cards => (
                                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6" key={cards.id}>

                                                                                {/* <div class="our-team">
                                                                                    <div className='d-flex justify-content-end'>
                                                                                        <div class="dropdown dropdown1">
                                                                                            <button class="btn  p-0 px-4 icon-vertical" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                
                                                                                                <FontAwesomeIcon icon={faEllipsisVertical} className='icon' />
                                                                                            </button>
                                                                                            <ul class="dropdown-menu dropdown-menu-white">

                                                                                                <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >
                                                                                                    
                                                                                                    <FontAwesomeIcon icon={faEye} className='icon-seting' />
                                                                                                    <Link class="nav-link" to="#">Detail</Link>
                                                                                                </li>
                                                                                                <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >
                                                                                                    <FontAwesomeIcon icon={faPen} className='icon-seting' />
                                                                                                    
                                                                                                    <Link class="nav-link" to="#">Modifier</Link>
                                                                                                </li>
                                                                                                <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >

                                                                                                    <FontAwesomeIcon icon={faTrashCan} className='icon-seting' />
                                                                                                    <Link class="nav-link" to="#">Supprimer</Link>
                                                                                                </li>


                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="picture mt-0">
                                                                                        {
                                                                                            cards.compte.photo ? <img src={FILE_URL + cards.compte.photo} className='img-fluid' alt='..' /> : <img src={logo_site} className='img-fluid' alt='..' />
                                                                                        }
                                                                                        
                                                                                    </div>
                                                                                    <div class="team-content">
                                                                                        <h3 class="name">{cards.compte.prenom} {cards.nom}</h3>
                                                                                        <h4 class="title">{cards.role}</h4>
                                                                                    </div>
                                                                                    <ul class="social">

                                                                                        <li><FontAwesomeIcon icon={faEnvelope} className='icon' /> <span className='text' key={cards.compte.emails[0].id}> {cards.compte.emails[0].email}</span></li>
                                                                                        <li><FontAwesomeIcon icon={faPhoneAlt} className='icon' /> <span className='text' key={cards.compte.phones[0].id}>{cards.compte.phones[0].phone}</span></li>

                                                                                    </ul>
                                                                                </div> */}

                                                                                <div class="our-team">
                                                                                    <div className='d-flex justify-content-end'>
                                                                                        <div class="dropdown dropdown1">
                                                                                            <button class="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                {/* <img src={seting} width="25px" alt='..' /> */}
                                                                                                <FontAwesomeIcon icon={faEllipsisVertical} className='icon' />
                                                                                            </button>
                                                                                            <ul class="dropdown-menu dropdown-menu-white">
                                                                                                <Link class="nav-link" to={`/cartes-detail/${cards.id}`}>
                                                                                                    <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >
                                                                                                        {/* <img src={detail} className='icon-seting' alt='...' /> */}
                                                                                                        <FontAwesomeIcon icon={faEye} className='icon-seting' />
                                                                                                        Detail
                                                                                                    </li>
                                                                                                </Link>

                                                                                                <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' data-bs-toggle="modal" data-bs-target="#supCarteModal" >

                                                                                                    <FontAwesomeIcon icon={faTrashCan} className='icon-seting' />
                                                                                                    <Link class="nav-link" to="#">Supprimer</Link>
                                                                                                </li>


                                                                                            </ul>
                                                                                        </div>
                                                                                        <div className="modal fade" id="supCarteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                                                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                                            <div className=" modal-dialog modal-dialog-centered ">
                                                                                                <div className="modal-content">
                                                                                                    <div className="modal-header">

                                                                                                        <h5 className='modal-title fs-4'>Supprimer la Carte</h5>

                                                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                                    </div>
                                                                                                    <div className="modal-body">

                                                                                                        <div className='text-center'>

                                                                                                            <p>Vous êtes sur le point de supprimer une carte. Voulez-vous continuer?</p>


                                                                                                        </div>

                                                                                                        <div className='d-flex justify-content-between align-item-center'>
                                                                                                            <button type="button" class="btn btn-danger px-3" data-bs-dismiss="modal">Non</button>
                                                                                                            <button className="btn btn-success px-3" type="button"
                                                                                                                // onClick={() => 
                                                                                                                //     handleDeleteCarte(cards.id)
                                                                                                                //     } 
                                                                                                                data-bs-dismiss="modal"> Oui</button>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="picture">
                                                                                        {
                                                                                            cards.compte.photo ? <img src={FILE_URL + cards.compte.photo} className='img-fluid' alt='..' /> : <img src={logo_site} className='img-fluid' alt='..' />
                                                                                        }

                                                                                    </div>
                                                                                    <div class="team-content">
                                                                                        <h5 class="name">{cards.compte.prenom} {cards.compte.nom}</h5>
                                                                                        <h4 class="title">{cards.profession}</h4>

                                                                                    </div>

                                                                                    <div class="social">
                                                                                        <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.compte.emails[0].email}`}>
                                                                                            <FontAwesomeIcon icon={faEnvelope} className='icon' />
                                                                                        </button>
                                                                                        <button type="button" class="" data-bs-container="body" data-bs-custom-class="custom-popover" data-bs-toggle="popover" data-bs-placement="top" data-bs-content={`${cards.compte.phones[0].phone}`}>
                                                                                            <FontAwesomeIcon icon={faPhoneAlt} className='icon' />
                                                                                        </button>


                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="tab-pane show fade" id="client-tab-pane" role="tabpanel" aria-labelledby="client-tab" tabindex="0">
                                                            <div class="container">
                                                                <div class="row">
                                                                    {
                                                                        Array.isArray(officeDetails.clients) && officeDetails.clients.map(cards => (
                                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6" key={cards.id}>

                                                                                <div class="our-team">
                                                                                    <div className='d-flex justify-content-end'>
                                                                                        <div class="dropdown">
                                                                                            <button class="btn  p-0 px-4 icon-vertical" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                                {/* <img src={seting} width="25px" alt='..' /> */}
                                                                                                <FontAwesomeIcon icon={faEllipsisVertical} className='icon' />
                                                                                            </button>
                                                                                            <ul class="dropdown-menu dropdown-menu-white">

                                                                                                <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >
                                                                                                    {/* <img src={detail} className='icon-seting' alt='...' /> */}
                                                                                                    <FontAwesomeIcon icon={faEye} className='icon-seting' />
                                                                                                    <Link class="nav-link" to="#">Detail</Link>
                                                                                                </li>
                                                                                                <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >
                                                                                                    <FontAwesomeIcon icon={faPen} className='icon-seting' />
                                                                                                    {/* <img src={modifier} className='icon-seting' alt='...' /> */}
                                                                                                    <Link class="nav-link" to="#">Modifier</Link>
                                                                                                </li>
                                                                                                <li className='d-flex justify-content-start align-items-center dropdown-item cursor-menu' >

                                                                                                    <FontAwesomeIcon icon={faTrashCan} className='icon-seting' />
                                                                                                    <Link class="nav-link" to="#">Supprimer</Link>
                                                                                                </li>


                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="picture mt-0">
                                                                                        {
                                                                                            cards.compte.photo ? <img src={FILE_URL + cards.compte.photo} className='img-fluid' alt='..' /> : <img src={logo_site} className='img-fluid' alt='..' />
                                                                                        }
                                                                                        {/* <img class="img-fluid" src="https://picsum.photos/130/130?image=1027" /> */}
                                                                                    </div>
                                                                                    <div class="team-content">
                                                                                        <h3 class="name">{cards.compte.prenom} {cards.nom}</h3>
                                                                                        <h4 class="title">{cards.role}</h4>
                                                                                    </div>
                                                                                    <ul class="social">

                                                                                        <li><FontAwesomeIcon icon={faEnvelope} className='icon' /> <span className='text' key={cards.compte.emails[0].id}> {cards.compte.emails[0].email}</span></li>
                                                                                        <li><FontAwesomeIcon icon={faPhoneAlt} className='icon' /> <span className='text' key={cards.compte.phones[0].id}>{cards.compte.phones[0].phone}</span></li>

                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}


                                    </div>

                                </div>
                            )}

                            {activeTab === 'Publication' && (
                                <div class="tab-pane fade p-md-3 show active" >

                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade show active" id="v-pills-Post" role="tabpanel" aria-labelledby="v-pills-Post-tab" tabindex="0">
                                            {posts.map(post => (
                                                <div key={post?.id} className="g-0 my-2 card border1 rounded" style={{ maxWidth: '100vw', height: '8.2em' }}>
                                                    <Link to={`/post-detail/${post?.id}`} className='nav-link' style={{ maxWidth: 'inherit' }}>
                                                        <div className='d-flex ' style={{ maxWidth: 'inherit' }}>
                                                            <img src={FILE_URL + post.couverture} className="rounded-start flex-shrink-1 imagePost" alt={post?.titre} />
                                                            <div className='post-content' style={{ position: 'relative' }}>

                                                                <div className='px-2'>
                                                                    <h5 className="prj-title text-truncate " >{post?.titre} </h5>
                                                                    <div className="description lh-sm " >{post?.description}</div>
                                                                </div>
                                                                <div className=" d-flex justify-content-between w-100" style={{ width: '100vw', position: 'absolute', bottom: '0', }}>
                                                                    <div className=''>
                                                                        <strong className='card-title'>Publié : </strong> {new Date(post?.createdAt).toLocaleDateString()}
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <div className='items mx-1'>
                                                                            <div className='icon-img '>
                                                                                <img src={comment} width='15px' height='15px' className='post-image-icon' alt="comments" />
                                                                            </div>
                                                                            <div className='icon-txt'>
                                                                                <span>{post?._count?.commentaires}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='items mx-1'>
                                                                            <div className='icon-img '>
                                                                                <img src={aime} width='15px' height='15px' className='post-image-icon' alt="likes" />
                                                                            </div>
                                                                            <div className='icon-txt'>
                                                                                <span>{post?._count?.likes}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='items mx-1'>
                                                                            <div className='icon-img '>
                                                                                <img src={non_aime} width='15px' height='15px' className='post-image-icon' alt="dislikes" />
                                                                            </div>
                                                                            <div className='icon-txt'>
                                                                                <span>{post?._count.dislikes}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Link>

                                                </div>
                                            ))}

                                        </div>

                                        <div class="tab-pane fade show" id="v-pills-Statut" role="tabpanel" aria-labelledby="v-pills-Statut-tab" tabindex="0">
                                            <div className="status-container">
                                                {statuts.map(statu => (

                                                    <div key={statu?.id} className="status-card">
                                                        {
                                                            statu?.organisation?.logo === null ?
                                                                <img src={logo_site} alt=".." className="profile-photo" />
                                                                :
                                                                <img src={FILE_URL + statu?.organisation?.logo} alt={`${statu?.organisation?.nom} ${statu?.organisation?.prenom}`} className="profile-photo" />


                                                        }
                                                        <Link onClick={() => { setIdStatus(statu?.id); handleShow() }} className='nav-link card-link story-background-dark'>
                                                            {statu?.media ? (
                                                                <img src={FILE_URL + statu?.media} alt="Story media" className="story-media1" />
                                                            ) : (
                                                                <div className="story-description" style={{ backgroundColor: statu?.color }}>
                                                                    {statu?.description}
                                                                </div>
                                                            )}
                                                        </Link>



                                                    </div>
                                                ))}
                                            </div>



                                        </div>

                                        <div class="tab-pane fade show" id="v-pills-Evenement" role="tabpanel" aria-labelledby="v-pills-Evenement-tab" tabindex="0">
                                            {evenements.map(event => (
                                                <div key={event.id} className="g-0 my-2 card border rounded">
                                                    <div className='d-flex'>
                                                        <img src={FILE_URL + event.couverture} className="rounded-start flex-shrink-1 imagePost" alt={event.titre} />


                                                        <div className='d-flex align-content-between flex-wrap'>
                                                            <div className='px-2 d-flex justify-content-end w-100'>
                                                                <img src={date} alt='...' />
                                                                <div className='px-2'>
                                                                    {new Date(event.date).toLocaleDateString()}

                                                                </div>
                                                            </div>
                                                            <div className='content px-2'>
                                                                <h6 className='prj-title'>{event.titre}</h6>
                                                                <div className='px-2 py-2'>
                                                                    {event.description.substring(0, 25) + "..."}

                                                                </div>
                                                                <div className='px-2 d-flex justify-content-start w-100'>
                                                                    <img src={location} alt='...' />
                                                                    <h6 className='px-2'>
                                                                        {event.lieu}
                                                                    </h6>
                                                                </div>

                                                            </div>

                                                            <div className='px-2 pb-1 d-flex justify-content-end w-100'>

                                                                <button className='btn btn-ternaire'>
                                                                    <Link to={`/event-detail/${event.id}`} className='nav-link'>

                                                                        voir detail
                                                                    </Link>
                                                                </button>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            ))}


                                        </div>

                                        <div class="tab-pane fade show" id="v-pills-Projet" role="tabpanel" aria-labelledby="v-pills-Projet-tab" tabindex="0">
                                            {projets.map(projet => (
                                                <div key={projet.id} className="g-0 my-2 card border1 rounded p-2">
                                                    <h5 className='prj-title'>
                                                        {projet.titre}
                                                    </h5>
                                                    <div className='prj-desc text-capitalize'>
                                                        {projet.description}
                                                    </div>
                                                    <div className='px-2 py-2 d-flex justify-content-start w-100'>
                                                        <img src={budget} alt='...' />
                                                        <div className='px-2 txt-bold'>
                                                            <span className='txt-bd'>Budget :</span>  Maxi: {projet.max} Min : {projet.min}
                                                        </div>
                                                    </div>
                                                    <div className='px-2 d-flex justify-content-start w-100'>
                                                        <img src={deadline} alt='...' />
                                                        <div className='px-2 txt-bold'>
                                                            <span className='txt-bd'>deadline : </span>  {new Date(projet.date).toLocaleDateString()}
                                                        </div>
                                                    </div>

                                                    <div className='px-2 py-3 d-flex justify-content-between w-100'>
                                                        <button className='prj-btn d-flex justify-content-center'>
                                                            <img src={interested} alt='...' /> <span className='txt-btn px-1'>Intéresser</span>
                                                        </button>
                                                        <button className='prj-btn1'>
                                                            <Link to={`/projet-detail/${projet.id}`} className='nav-link'>

                                                                voir detail
                                                            </Link>
                                                        </button>

                                                    </div>

                                                </div>
                                            ))}


                                        </div>

                                        <div class="tab-pane fade show" id="v-pills-Sondage" role="tabpanel" aria-labelledby="v-pills-Sondage-tab" tabindex="0">
                                            {surveys.map(survey => (
                                                // <div key={survey.id} className="g-0 my-2 card border rounded">
                                                //     <div className="poll">
                                                //         <div className="poll-header  d-flex align-items-center">

                                                //             {
                                                //                 (survey.couverture) && <img src={`${FILE_URL}${survey.couverture}`} alt={`${survey.publiePar.nom} ${survey.publiePar.prenom}`} className="sondage-image1" />
                                                //             }


                                                //         </div>
                                                //         <div className="poll-header d-flex align-items-center">

                                                //             <h5 className="ms-2 pt-2 text-truncate" style={{ maxWidth: '100vw' }}>{survey.question}</h5>
                                                //         </div>
                                                //         <div className="poll-header d-flex align-items-center py-2">
                                                //             {
                                                //                 (survey.multiple) ? <>
                                                //                     <FontAwesomeIcon icon={faCheckCircle} className='check-icon' />
                                                //                     <div className='style-info-mutiple-option px-2'>Sélectionnez une ou plusieurs options</div>
                                                //                 </> : <>
                                                //                     <FontAwesomeIcon icon={faCheckCircle} className='check-icon' />
                                                //                     <div className='style-info-mutiple-option px-2'>Sélectionnez une seule option</div>
                                                //                 </>
                                                //             }

                                                //         </div>
                                                //         {survey.options.map(option => (
                                                //             <div key={option.id} onClick={() => handleVote(survey.id, option.id)}>
                                                //                 <div className="poll-option d-flex justify-content-between align-items-center">
                                                //                     <div>
                                                //                         <input
                                                //                             type="checkbox"
                                                //                             name={`poll-${survey.id}`}
                                                //                             checked={option.votes.some(vote => vote.compte.id === currentUser.id)}
                                                //                             style={{ color: option.votes.some(vote => vote.compte.id === currentUser.id) ? '#007bff' : 'inherit' }}
                                                //                             readOnly
                                                //                         />
                                                //                         {option.option}
                                                //                     </div>
                                                //                     <div className='d-flex'>
                                                //                         <div>
                                                //                             {getLastTwoVoters(option.votes).map((photo, index) => (
                                                //                                 <img key={index} src={`${FILE_URL}${photo}`} alt="voter" className="voter-photo" />
                                                //                             ))}
                                                //                         </div>
                                                //                         <div>
                                                //                             {option._count.votes}
                                                //                         </div>
                                                //                     </div>
                                                //                 </div>
                                                //                 <div className="poll-bar poll-bar1">
                                                //                     <div
                                                //                         className={`${option._count.votes === 0 ? '' : 'poll-bar-inner'} `}
                                                //                         style={{
                                                //                             width: `${(option._count.votes / survey.options.reduce((acc, opt) => acc + opt._count.votes, 0)) * 100}%`,

                                                //                         }}
                                                //                     ></div>
                                                //                 </div>
                                                //             </div>
                                                //         ))}
                                                //     </div>
                                                // </div>
                                                <div key={survey.id} className="g-0  card card-sondage border1 rounded " style={{ position: 'relative', minWidth: '15em', maxWidth: '100vw' }}>
                                                    <div className="poll">
                                                        <div className="poll-header  d-flex align-items-center">

                                                            {
                                                                (survey.couverture) && <img src={`${FILE_URL}${survey.couverture}`} alt={`${survey.publiePar.nom} ${survey.publiePar.prenom}`} className="sondage-image" />
                                                            }


                                                        </div>
                                                        <div className="poll-header d-flex align-items-center">

                                                            <h5 className="prj-title ms-2 pt-2 text-truncate" style={{ maxWidth: '100vw' }}>{survey.question}</h5>
                                                        </div>
                                                        <div className="poll-header d-flex align-items-center py-2">
                                                            {
                                                                (survey.multiple) ? <>
                                                                    <FontAwesomeIcon icon={faCheckCircle} className='check-icon' />
                                                                    <div className='style-info-mutiple-option px-2'>Sélectionnez une ou plusieurs options</div>
                                                                </> : <>
                                                                    <FontAwesomeIcon icon={faCheckCircle} className='check-icon' />
                                                                    <div className='style-info-mutiple-option px-2'>Sélectionnez une seule option</div>
                                                                </>
                                                            }

                                                        </div>
                                                        {survey.options.slice(0, 3).map(option => (
                                                            <div key={option.id} onClick={() => handleVote(survey.id, option.id)} className="border-poll">
                                                                <div className="poll-option  d-flex justify-content-between align-items-center">
                                                                    <div>
                                                                        <input
                                                                            type="checkbox"
                                                                            name={`poll-${survey.id}`}
                                                                            checked={option.votes.some(vote => vote.compte.id === currentUser.id)}
                                                                            style={{ color: option.votes.some(vote => vote.compte.id === currentUser.id) ? '#007bff' : 'inherit' }}
                                                                            readOnly
                                                                        />
                                                                        {option.option}
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <div>
                                                                            {getLastTwoVoters(option.votes).map((photo, index) => (
                                                                                photo == null ? <img key={index} src={logo_site} alt="voter" className="voter-photo" /> : <img key={index} src={`${FILE_URL}${photo}`} alt="voter" className="voter-photo" />
                                                                            ))}
                                                                        </div>
                                                                        <div>
                                                                            {option._count.votes}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="poll-bar poll-bar1">
                                                                    <div
                                                                        className={`${option._count.votes === 0 ? '' : 'poll-bar-inner'} `}
                                                                        style={{
                                                                            width: `${(option._count.votes / survey.options.reduce((acc, opt) => acc + opt._count.votes, 0)) * 100}%`,

                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        &nbsp;

                                                        <div className='voir' style={{ position: 'absolute', bottom: '0', left: '10vw', minWidth: '15em', maxWidth: '100vw' }}>

                                                            <Link className='nav-link ' to={`/sondage-detail/${survey.id}`}>
                                                                Voir detail
                                                            </Link>

                                                        </div>

                                                    </div>

                                                </div>
                                            ))}

                                            {/* Modal */}
                                            {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Vote</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>{modalMessage}</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                                                        Fermer
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal> */}
                                            <ToastContainer transition={Zoom} />


                                        </div>


                                    </div>


                                </div>

                            )}

                            {activeTab === 'Services' && (
                                <div className="tab-pane fade show active">

                                    <div class="tab-content" id="v-pills-tabContent">
                                        {serviceDetails && (
                                            <div className="tab-pane d-flex justify-content-center fade show active" id={`v-pills-${serviceDetails.nom}`} role="tabpanel" aria-labelledby={`v-pills-${serviceDetails.nom}-tab`} tabIndex="0">
                                                <div className="w-100 p-3 text-justify">

                                                    {serviceDetails.description}

                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            )}






                        </div>

                    </div>
                </div>












                <div>
                    <ToastContainer transition={Zoom} />
                    <OrganizationForm OrganisationToEdit={item} isEditMode={isEditMode} onOrganisationAdded={() => {/* Rafraîchir la liste des posts */ }} onOrganisationUpdated={handleOrganisationUpdated} />
                    <StatusViewer id={idStatus} show={showModalStatut} handleClose={handleClose} />
                </div>

            </div>
            <div className="col-4">
                <Left />

            </div>
        </div>
    )
}

export default OrganisationDetail;