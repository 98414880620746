/* eslint-disable jsx-a11y/anchor-is-valid */

import './Accueil.css'
import Left from './Left'
import Status from './Status';
import EvenementAccueil from './EvenementAccueil';
import SondageAccueil from './SondageAccueil';
import PostAccueil from './postAccueil'
import ProjetAccueil from './ProjetAccueil'
import StatusEntreprise from './StatusEntreprise';

// import { FaCalendarAlt, FaUpload } from 'react-icons/fa';
function Accueil() {


    return (
        <div className="row m-md-2">


            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                <Status />
                <div className='Entreprise p-md-3'>

                    <div className='px-2 txt-head'>
                        Entreprise
                    </div>
                    <StatusEntreprise />
                    <PostAccueil />
                    <EvenementAccueil />
                    <SondageAccueil />
                    <ProjetAccueil />
                </div>
            </div>
            <div className="col-4">

                <Left />


            </div>
        </div>
    )
}

export default Accueil;