/* eslint-disable jsx-a11y/anchor-is-valid */

import Left from '../Accueil/Left'
import './Organisation.css'
import MesOrganisation from './MesOrganisation'
import AbonneeOrganisation from './AbonneeOrganisation'
import PaysOrganisation from './PaysOrganisation'
function Organisation() {

    return (
        <div className="row m-md-2">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                <MesOrganisation/>
                <AbonneeOrganisation />
                <PaysOrganisation />
            </div>
            <div className="col-4">
                <Left />
            </div>
        </div>
    )

}

export default Organisation;