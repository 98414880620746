import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import {Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ProfilForm({ onProfilAdded, onProfilUpdated, profilToEdit, isEditMode }) {
    const currentUser = JSON.parse(localStorage.getItem('account'))
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        photo: "",
        video: "",
        presentation: "",
        profession: "",
        adresse: "",
        site: "",
        social: {
            twitter: "",
            facebook: "",
            linkedin: "",
            instagram: ""
        },
        emails: [
            {
                id: "",
                libelle: "Principal",
                email: "",
                createdAt: ""
            }
        ],
        phones: [
            {
                id: "",
                libelle: "Principal",
                phone: "",
                country: {
                    id: "",
                    code: "",
                    iso: "",
                    nom: ""
                },
                createdAt: ""
            }
        ]
    });

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countries, setCountries] = useState([]);
    const [showModalCountry, setShowModalCountry] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/countries`);
                setCountries(response.data);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);
    useEffect(() => {
        if (profilToEdit) {
            setFormData({
                nom: profilToEdit.nom,
                prenom: profilToEdit.prenom,
                photo: profilToEdit.photo,
                video: profilToEdit.video,
                presentation: profilToEdit.presentation,
                profession: profilToEdit.profession,
                adresse: profilToEdit.adresse,
                site: profilToEdit.site,
                social: {
                    twitter: profilToEdit.social.twitter,
                    facebook: profilToEdit.social.facebook,
                    linkedin: profilToEdit.social.linkedin,
                    instagram: profilToEdit.social.instagram
                },
                emails: [
                    {
                        id: "",
                        libelle: "Principal",
                        email: profilToEdit.emails[0].email,
                        createdAt: ""
                    }
                ],
                phones: [
                    {
                        id: "",
                        libelle: "Principal",
                        phone: profilToEdit.phones[0].phone,
                        country: {
                            id: "",
                            code: "",
                            iso: "",
                            nom: ""
                        },
                        createdAt: ""
                    }
                ]
            });
        }
    }, [profilToEdit]);

    const handleCountrySelect = (country) => {
        setSelectedCountry(country);
        setShowModalCountry(false);
    };
    const handlePhoneChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    // useEffect(() => {
    //     const accessToken = localStorage.getItem('accessToken');
    //     axios.get('/api/v1/accounts/me', {
    //         headers: {
    //             Authorization: `Bearer ${accessToken}`
    //         }
    //     })
    //         .then(response => {
    //             setFormData(response.data);
    //         })
    //         .catch(error => {
    //             console.error("There was an error fetching the data!", error);
    //         });
    // }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({
            ...formData,
            [name]: files[0]
        });
    };

    const handleSocialChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            social: {
                ...formData.social,
                [name]: value
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        const formDataToSend = new FormData();

        for (const key in formData) {
            if (key === 'social') {

                formDataToSend.append('social', JSON.stringify(formData.social));

            } else if (key === 'emails' || key === 'phones') {
                formData[key].forEach((item, index) => {
                    for (const itemKey in item) {
                        formDataToSend.append(`${key}[${index}][${itemKey}]`, item[itemKey]);
                    }
                });
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }

        // Affichage de la valeur de formDataToSend dans la console
        console.log('Valeurs envoyées :');
        formDataToSend.forEach((value, key) => {
            console.log(`${key}:`, value);
        });

        axios.put(`${process.env.REACT_APP_API_URL}/api/v1/accounts/${currentUser.id}`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response => {
                console.log("Profile updated successfully!", response.data);
                setLoading(false);
                if(response.data.code){

                }else{
                    if (isEditMode) {
                        notify(isEditMode ? "Profil modifié avec succès !" : "Profil ajouté avec succès !");
                        onProfilUpdated(response.data);
                    }
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
                notify("Erreur lors de la modification du profil");
            });
    };

    return (
        <div className="modal fade" id="compteModify" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-lg modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-4" id="exampleModalLabel">Modifier Profile</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="nom" className="form-label">Nom</label>
                                <input type="text" className="form-control" id="nom" name="nom" value={formData.nom} onChange={handleChange} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="prenom" className="form-label">Prénom</label>
                                <input type="text" className="form-control" id="prenom" name="prenom" value={formData.prenom} onChange={handleChange} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="photo" className="form-label">Photo</label>
                                <input type="file" className="form-control" id="photo" name="photo" onChange={handleFileChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="video" className="form-label">Vidéo</label>
                                <input type="file" className="form-control" id="video" name="video" onChange={handleFileChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="presentation" className="form-label">Présentation</label>
                                <textarea className="form-control" id="presentation" name="presentation" value={formData.presentation} onChange={handleChange}></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="profession" className="form-label">Profession</label>
                                <input type="text" className="form-control" id="profession" name="profession" value={formData.profession} onChange={handleChange} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="adresse" className="form-label">Adresse</label>
                                <input type="text" className="form-control" id="adresse" name="adresse" value={formData.adresse} onChange={handleChange} required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="site" className="form-label">Site</label>
                                <input type="text" className="form-control" id="site" name="site" value={formData.site} onChange={handleChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="twitter" className="form-label">Twitter</label>
                                <input type="text" className="form-control" id="twitter" name="twitter" value={formData.social.twitter} onChange={handleSocialChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="facebook" className="form-label">Facebook</label>
                                <input type="text" className="form-control" id="facebook" name="facebook" value={formData.social.facebook} onChange={handleSocialChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="linkedin" className="form-label">LinkedIn</label>
                                <input type="text" className="form-control" id="linkedin" name="linkedin" value={formData.social.linkedin} onChange={handleSocialChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="instagram" className="form-label">Instagram</label>
                                <input type="text" className="form-control" id="instagram" name="instagram" value={formData.social.instagram} onChange={handleSocialChange} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="email" className="form-control" id="email" name="email" value={formData.emails[0].email} onChange={handleChange} required />
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    id="countryCode"
                                    className="form-control select-container"
                                    placeholder="indicatif"
                                    value={selectedCountry ? selectedCountry.code : ''}
                                    onClick={() => setShowModalCountry(true)}
                                    readOnly
                                />
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    className="form-control select-container1"
                                    placeholder="70 70 70 70"
                                    value={formData.phones[0].phone}
                                    readOnly
                                    onChange={handlePhoneChange}
                                />

                            </div>
                            <button className='btn-ad' type="submit" disabled={loading}>
                                        {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : isEditMode ? 'Modifier' : 'Ajouter'}
                                    </button>
                        </form>
                    </div>
                </div>
            </div>

            <Modal show={showModalCountry} onHide={() => setShowModalCountry(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Selectionné le Pays</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="list-group">
                        {countries.map((country) => (
                            <li
                                key={country.id}
                                className="list-group-item"
                                onClick={() => handleCountrySelect(country)}
                                style={{ cursor: 'pointer' }}
                            >
                                {country.nom} ({country.code})
                            </li>
                        ))}
                    </ul>
                </Modal.Body>

            </Modal>
            <ToastContainer transition={Zoom} />
        </div>
    )
}

export default ProfilForm;