/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import moi from '../image/image.png'
import comment from '../image/icon-card/commentaire.png'
import aime from '../image/icon-card/aime.png'
import non_aime from '../image/icon-card/pas-aime.png'
import add from '../image/icon-card/add.png'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PostForm from '../Post/AddPost'
import { Zoom, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SkeletonLoaderPost from '../SkeletonLoad/skeletonloaderPost'

function PostAccueil() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    // const organisation = JSON.parse(localStorage.getItem('organisation'))
    const [organisation, setOrganisation] = useState(JSON.parse(localStorage.getItem('organisation')) || '');
    const notify = (message, type = 'success') => {
        toast[type](message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/posts`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                // Trier les posts par date de création (du plus récent au plus ancien)
                const sortedPosts = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                // Sélectionner les six derniers posts
                const latestPosts = sortedPosts.slice(0, 6);
                setPosts(latestPosts);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching posts:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handlePostAdded = (newPost) => {
        setPosts([newPost, ...posts]);
    };



    useEffect(() => {
        // Définir un intervalle qui vérifie la valeur dans le localStorage toutes les secondes
        const intervalId = setInterval(() => {
            const currentOrganisation = localStorage.getItem('organisation') || '';
            if (currentOrganisation !== organisation) {
                setOrganisation(currentOrganisation);
            }
        }, 1000); // Intervalle de 1 seconde

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(intervalId);
    }, [organisation]); // Dépendance à l'état "organisation"


    const VerificatioCompte = (e) => {

        if (!organisation) {
            notify('Veillez changer de compte pour pouvoir créer un post', 'info')
        } else {
            // Ouvre le modal Bootstrap pur avec jQuery ou JavaScript pur
            const scanModal = document.getElementById('addpostModal');
            const bootstrapModal = new window.bootstrap.Modal(scanModal);
            bootstrapModal.show();
        }
    }


    const chunkedPosts = chunkArray(posts, 2);

    if (loading) {
        return (
            <SkeletonLoaderPost />
        );
    } else if (posts.length === 0) {
        return (
            <div className="right p-2 mx-2 my-2 h-25 position-relative">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>Posts</div>
                    <div className='px-2 btn-add' onClick={VerificatioCompte} data-bs-toggle="modal" data-bs-target="#addpostModal">
                        <img src={add} className="rounded-start flex-shrink-1" alt="..." />
                    </div>
                </div>
                <div className=' w-100 h-75 bg-body-secondary'>

                    <h6 className='position-absolute top-50 start-50 translate-middle text-center'>Aucun Post disponible</h6>

                </div>
                <PostForm onPostAdded={handlePostAdded} />

            </div>
        )
    }
    return (
        <>
            <div className="right p-2 mx-2 my-2">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>Posts</div>
                    <div className='px-2 btn-add' onClick={VerificatioCompte}>
                        <img src={add} className="rounded-start flex-shrink-1" alt="..." />
                    </div>
                </div>


                <div id="carouselExampleCaptions" className="carousel carousel-dark slide" data-bs-ride="false">
                    <div className="carousel-inner">
                        {chunkedPosts.map((chunk, chunkIndex) => (
                            <div key={chunkIndex} className={`carousel-item ${chunkIndex === 0 ? 'active' : ''}`}>
                                <div className="card-group grip gap-2 section-container">
                                    {chunk.map(post => (
                                        <div key={post.id} className="g-0 card border1 rounded" style={chunkedPosts.length === 1 || chunk.length === 1 ? { minWidth: '20em' } : { minWidth: '20em' }}>
                                            <Link to={`/post-detail/${post.id}`} className='nav-link' style={{width: 'inherit'}}>
                                                <div className='d-flex'>
                                                    <img src={FILE_URL + post.couverture} className="rounded-start flex-shrink-1 imagePost" alt={post.titre} />
                                                    <div className='post-content' style={{ position: 'relative' }}>

                                                        <div className='px-2'>
                                                            <h5 className="prj-title text-truncate " >{post.titre} </h5>
                                                            <div className="description lh-sm " >{post.description}</div>
                                                        </div>
                                                        <div className="bottom-content d-flex justify-content-between" style={{ position: 'absolute', bottom: '0', }}>
                                                            <div className=''>
                                                                <strong className='card-title'>Publié : </strong> {new Date(post.createdAt).toLocaleDateString()}
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='items mx-1'>
                                                                    <div className='icon-img '>
                                                                        <img src={comment} width='15px' height='15px' className='post-image-icon' alt="comments" />
                                                                    </div>
                                                                    <div className='icon-txt'>
                                                                        <span>{post._count.commentaires}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='items mx-1'>
                                                                    <div className='icon-img '>
                                                                        <img src={aime} width='15px' height='15px' className='post-image-icon' alt="likes" />
                                                                    </div>
                                                                    <div className='icon-txt'>
                                                                        <span>{post._count.likes}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='items mx-1'>
                                                                    <div className='icon-img '>
                                                                        <img src={non_aime} width='15px' height='15px' className='post-image-icon' alt="dislikes" />
                                                                    </div>
                                                                    <div className='icon-txt'>
                                                                        <span>{post._count.dislikes}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="carousel-indicators">
                        {chunkedPosts.map((chunk, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselExampleCaptions"
                                data-bs-slide-to={index}
                                className={index === 0 ? 'active' : ''}
                                aria-current={index === 0 ? 'true' : 'false'}
                                aria-label={`Slide ${index + 1}`}
                            ></button>
                        ))}
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-center">
                    {
                        posts.length > 2 ? (
                            <div className="d-flex w-100 justify-content-center">
                                <Link to={`/post-list`} className='nav-link'>
                                    Afficher tous les posts &rarr;
                                </Link>

                            </div>
                        ) : null
                    }

                </div>

            </div>
            <ToastContainer transition={Zoom} />
            <PostForm onPostAdded={handlePostAdded} />
        </>
    )
}

export default PostAccueil;

// Utility function to chunk the array
const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
};