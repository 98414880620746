
/* eslint-disable jsx-a11y/anchor-is-valid */
import entreprise from '../image/Organisation-logo/entreprise.png'
import email from '../image/Organisation-logo/email.png'
import phone from '../image/Organisation-logo/phone.png'
import web from '../image/Organisation-logo/web.png'

import './Organisation.css'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import OrganizationForm from './AddOrganisation'
import SkeletonLoaderPost from '../SkeletonLoad/skeletonloaderPost'

function AbonneeOrganisation() {
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/";
    const id = "abonne";
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/organizations/subcribe`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                // Trier les organisation par date de création (du plus récent au plus ancien)
                const sortedOrganisation = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                // Sélectionner les six derniers posts
                const latestOrganisation = sortedOrganisation.slice(0, 6);
                setOrganizations(latestOrganisation);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching organisation:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    const chunkedPosts = chunkArray(organizations, 2);
    
    if (loading) {
        return (
            <SkeletonLoaderPost />
        );
    } else if (organizations.length === 0) {
        return (
            <div className="right p-2 mx-2 my-2 h-75 position-relative">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>Organisations Abonnées</div>
                    
                </div>
                <div className=' w-100 h-75 bg-body-secondary'>

                    <h6 className='position-absolute top-50 start-50 translate-middle text-center'>Aucune Organisations Abonnées disponible</h6>

                </div>

                <OrganizationForm />

            </div>
        )
    }
    return (
        <div>
            <div className="right p-2 mx-2 my-2">
                <div className='d-flex my-2'>
                    <div className='px-2 txt-head'>Organisations Abonnées</div>
                    {/* <div className='px-2 btn-add' data-bs-toggle="modal" data-bs-target="#addOrganisation">
                        <img src={add} className="rounded-start flex-shrink-1" alt="..." />
                    </div> */}
                </div>

                <div id="carouselAbonnneOrganisation" className="carousel carousel-dark slide" data-bs-ride="false">
                    <div className="carousel-inner">
                        {chunkedPosts.map((chunk, chunkIndex) => (
                            <div key={chunkIndex} className={`carousel-item ${chunkIndex === 0 ? 'active' : ''}`}>
                                <div className="card-group grip gap-2 section-container">
                                    {chunk.map(organization => (
                                        <div key={organization.id} className="g-0 card border1 rounded py-2" style={{ minWidth: '18em', maxWidth: '30vw' }}>
                                            <Link to={`/organisation-detail/${organization.id}`} className='nav-link'>
                                                <div className='d-flex'>
                                                    <div className='d-flex align-items-center px-3'>
                                                        <img src={FILE_URL + organization.logo} class="flex-shrink-0 imageOrganisation" alt={organization.nom} />
                                                    </div>


                                                    <div className='d-flex align-content-between flex-wrap'>
                                                        <div className='px-1'>
                                                        <h5 class="prj-title text-truncate">{organization.nom}</h5>
                                                            <div class="text">{organization.adresse}</div>
                                                        </div>
                                                        <div class="w-100 px-2">

                                                            <div className='pt-2 d-flex justify-content-start w-100'>
                                                                <img src={entreprise} className='rounded-circle img-org' alt='...' />
                                                                <div className='px-2'>
                                                                    {organization.domaine}
                                                                </div>
                                                            </div>
                                                            <div className=' pt-2 d-flex justify-content-start w-100'>
                                                                <img src={phone} className='rounded-circle img-org' alt='...' />
                                                                <div className='px-2'>
                                                                    {organization.phone}
                                                                </div>
                                                            </div>
                                                            <div className=' pt-2 d-flex justify-content-start w-100'>
                                                                <img src={email} className='rounded-circle img-org' alt='...' />
                                                                <div className='px-2 text-truncate' style={{ maxWidth: '150px' }}>
                                                                    {organization.email}
                                                                </div>
                                                            </div>
                                                            <div className=' pt-2 d-flex justify-content-start w-100'>
                                                                <img src={web} className='rounded-circle img-org' alt='...' />
                                                                <div className='px-2'>
                                                                    {organization.site}
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>
                                            </Link>


                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="carousel-indicators">
                        {chunkedPosts.map((chunk, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselAbonnneOrganisation"
                                data-bs-slide-to={index}
                                className={index === 0 ? 'active' : ''}
                                aria-current={index === 0 ? 'true' : 'false'}
                                aria-label={`Slide ${index + 1}`}
                            ></button>
                        ))}
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-center">
                    {
                        organizations.length > 2 ? (
                            <div className="d-flex w-100 justify-content-center">
                                <Link  to={`/organisation-list/${id}`} className='afficher nav-link'>Afficher tous les organisations abonnées &rarr;</Link>
                                
                            </div>
                        ) : null
                    }

                </div>

            </div>
            <OrganizationForm />
            {/* <div class="modal fade" id="addAbonneeOrganisation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered  modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-4" id="exampleModalLabel">Créer Organisation</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <form>

                                <div className='mb-3'>

                                    <label for="nom" class="form-label">Nom</label>
                                    <input type='text' name='nom' class="form-control" placeholder="Veuillez saisir nom" id='nom' />

                                </div>

                                <div class="mb-3">
                                    <label for="description" class="form-label">Description</label>
                                    <textarea name="description" rows="3" id="description" formControlName="description" placeholder="Veuillez saisir la description de votre post"
                                        class="form-control"></textarea>

                                </div>

                                <div className='mb-3'>

                                    <label for="date" class="form-label">Date</label>
                                    <input type='date' name='date' class="form-control" placeholder="Veuillez Entrez la date de publication" id='date' />

                                </div>

                                <div className='mb-3'>

                                    <label for="image" class="form-label">Image</label>
                                    <input type='file' name='image' class="form-control" id='image' />

                                </div>

                                <div className="w-100 d-flex justify-content-end">
                                    <button className='btn-ad'>
                                        Ajouter
                                    </button>

                                </div>

                            </form>
                        </div>

                    </div>
                </div>
            </div> */}


        </div>
    )
}

export default AbonneeOrganisation

// Utility function to chunk the array
const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
};