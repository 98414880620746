/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line
import Left from '../Accueil/Left';

// import add from '../image/icon-card/add.png'
// import entreprise from '../image/Organisation-logo/entreprise.png'
import email from '../image/Organisation-logo/email.png'
import phone from '../image/Organisation-logo/phone.png'
// eslint-disable-next-line
import linkeldin from '../image/linkedin.png'
import web from '../image/Organisation-logo/web.png'
// import Left from '../Accueil/Left'
import './Profil.css'
import modifier from '../image/modifier.png'
// import couverture from '../image/profil-image/Rectangle 181.png'
// import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react'; // Use QRCodeCanvas or QRCodeSVG
import ProfilForm from './ModifyProfil';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faSquareInstagram, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
function Profil() {
    const [profile, setProfile] = useState([]);
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const FILE_URL = "https://holilinkv2storage.blob.core.windows.net/main/"
    const currentUser = JSON.parse(localStorage.getItem('account'))
    // Créer la chaîne à partir des données JSON
    const qrData = JSON.stringify(
        currentUser?.id + "/" +
        currentUser?.nom + "/" +
        currentUser?.prenom
    );
    const [isEditMode, setIsEditMode] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const accessToken = localStorage.getItem('accessToken');
            console.log('profile', accessToken)
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/accounts/me`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                setProfile(response.data);
                console.log('profile', response.data)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching profile:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    const handleProfilUpdated = (updatedProjet) => {
        setProfile(updatedProjet);
    };
    if (loading) {
        return (
            <div className="row m-2">
                <div className="col-1"></div>
                <div className="col-12 col-md-12 col-sm-12 col-lg-8">
                    <div className="right my-2">
                        <Skeleton height={200} className="cover-picture-style" />

                        <div className='d-flex justify-content-around align-items-center pb-0'>
                            <Skeleton circle={true} height={100} width={100} className="profil-picture-style" />
                            <div className="container-name-presentation">
                                <Skeleton width={150} height={20} />
                                <Skeleton width={100} height={20} />
                            </div>
                            <Skeleton circle={true} height={30} width={30} />
                        </div>
                    </div>

                    <div className="right my-2 px-5 py-2">
                        <Skeleton width={100} height={20} />
                        <Skeleton count={3} />
                    </div>
                </div>

                <div className="col-12 col-md-12 col-sm-12 col-lg-3">
                    <div className="right my-2 px-2 py-2">
                        <Skeleton height={300} className='w-100' />
                    </div>

                    <div className='right my-2 py-2'>
                        <Skeleton width={100} height={20} />
                        <div className="d-flex justify-content-center">
                            <Skeleton height={256} width={256} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="row m-md-2">
            <div className="col-1"></div>
            <div className="col-12 col-md-12 col-sm-12 col-lg-8">
                <div className="right my-2">
                    <img src={FILE_URL + profile.photo} alt="cover" className="cover-picture-style" />

                    <div className='d-flex justify-content-around align-items-center pb-0'>
                        <img src={FILE_URL + profile.photo} alt="cover" className="profil-picture-style" />
                        <div className="container-name-presentation">
                            <h4 className="text-black">{profile.prenom} {profile.nom}</h4>
                            <span>
                                {
                                    profile.profession
                                }
                            </span>
                        </div>
                        <button type='button' data-bs-toggle="modal" data-bs-target="#compteModify" onClick={() => setIsEditMode(true)} className="btn btn-sm ms-2">

                            <img src={modifier} width="30px" alt='modifier' />
                        </button>
                    </div>
                </div>

                <div className="right my-2 px-2  py-2">
                    <h5 >
                        Apropos
                    </h5>
                    <span>
                        {
                            profile.presentation
                        }
                    </span>

                    <div className='identifiant mt-2'>
                        <div className='d-flex mb-2'>
                            <img src={email} className='image-icon' alt='...' />
                            <div key={profile.emails[0]?.id}>{profile.emails[0]?.email}</div>
                        </div>
                        <div className='d-flex mb-2'>
                            <img src={phone} className='image-icon' alt='...' />
                            <div key={profile.phones[0]?.id}>{profile.phones[0]?.phone}</div>
                        </div>

                        <div className='d-flex mb-2'>
                            <img src={web} className='image-icon' alt='...' />
                            {
                                profile.site ? <div>{profile.site}</div> : <div>holilink.ml</div>
                            }
                        </div>

                    </div>
                </div>
                <ProfilForm profilToEdit={profile} isEditMode={isEditMode} onProfilAdded={() => {/* Rafraîchir la liste des posts */ }} onProfilUpdated={handleProfilUpdated} />
            </div>


            <div className="col-12 col-md-12 col-sm-12 col-lg-3">
                <div className="right my-2 px-2 py-2">
                    <div className="video-container" key={profile?.id}>


                        <video controls className='w-100'
                            height="300">
                            <source src={FILE_URL + profile?.video} type="video/webm" />

                            <source src={FILE_URL + profile?.video} type="video/mp4" />

                            Download the
                            <a href={FILE_URL + profile?.video}>WEBM</a>
                            or
                            <a href={FILE_URL + profile?.video}>MP4</a>
                            video.
                        </video>
                    </div>
                </div>

                <div className='right my-2 py-2'>

                    <div className=" d-flex justify-content-center ">

                        <QRCodeCanvas value={qrData} size={256} level={"H"} />
                    </div>
                </div>

                <div className='right my-2 py-2'>

                    <div className='d-flex align-items-center mb-2'>
                        <FontAwesomeIcon className='image-icon' icon={faFacebook} />
                        {
                            profile.social.facebook ? <div>{profile.social.facebook}</div> : <div>Pas de compte facebook</div>
                        }
                    </div>
                    <div className='d-flex align-items-center mb-2'>
                        <FontAwesomeIcon className='image-icon' icon={faSquareXTwitter} />
                        {
                            profile.social.twitter ? <div>{profile.social.twitter}</div> : <div>Pas de compte twitter</div>
                        }
                    </div>
                    <div className='d-flex align-items-center mb-2'>
                        <FontAwesomeIcon className='image-icon' icon={faLinkedin} />
                        {
                            profile.social.linkedin ? <div>{profile.social.linkedin}</div> : <div>Pas de compte linkedin</div>
                        }
                    </div>
                    <div className='d-flex align-items-center mb-2'>
                        <FontAwesomeIcon className='image-icon' icon={faSquareInstagram} />
                        {
                            profile.social.instagram ? <div>{profile.social.instagram}</div> : <div>Pas de compte instagram</div>
                        }
                    </div>
                </div>


            </div>
        </div>
    )

}

export default Profil;